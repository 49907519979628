import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'
import gbUtils from './utils/gbUtils'
import i18n from './i18n'
import '../src/plugins/vant'
import web3 from './utils/web3'
import tron from './utils/justSwap/tron'
import Loading from './components/common/Loading'
import '@/static/icons' // 本地svg图
Vue.component('Loading', Loading)

export default i18n

Vue.prototype.$_ = _
Vue.prototype.$moment = moment
Vue.prototype.$axios = axios
Vue.prototype.$gbUtils = gbUtils
Vue.prototype.$web3 = web3
Vue.prototype.$tron = tron
Vue.config.productionTip = false
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
