export const EN = {
  '/': 'Airdrop',
  '/login': 'Login',
  '/gameGuild': 'Guild Hall',
  '/gameLink': 'Game Link',
  '/twins': 'Share',
  '/videoList': 'video',
  '/video': 'video',
  '/warehouse': 'Treasure box',
  // 钱包 start
  '/createWallet': 'Create a wallet',
  '/importWallet': 'Import the wallet',
  '/tokenDetail': 'TokenDetail',
  // '/walletDetail': 'Wallet Detail',
  '/backupPrivateKey': 'Backup Private Key',
  '/ChangeWalletName': 'Change Wallet Name',
  '/deleteWallet': 'Delete Wallet',
  '/transactionDetail': 'Transaction Detail',
  '/transfer': 'Transfer',
  '/receive': 'Receive',
  '/swap': 'Swap',
  '/getLP': 'Get The LP',
  '/staking': 'Stake',
  '/redeem': 'Redeem',
  // 钱包 end
  '/gameLinkDetail': 'GameLinkDetail',
  '/notice': 'Announce',
  '/noticeDetail': 'AnnounceDetail',
  // 双生 strat
  '/myFriends': 'My Friend',
  '/Invitation': 'Share',
  // 公会
  '/browers': 'Browers',
  // 彩票
  '/lottery': 'Lottery',
  '/lotteryHistroy': 'Lottery',
  '/lotteryRule': 'How to Play',
  '/myLottery': 'My',
  // 骑士卡
  '/knight': 'Knight',
  // 社区
  '/community': 'Community',
  '/communityDetail': 'Community Detail',
  '/createCommunity': 'Create Community',
  '/setCommunity': 'Set',
  '/setCommunityName': 'Community name settings',
  '/setCommunityDec': 'Community profile settings',
  '/setCommunityBtn': 'Personalized button settings',
  '/communitys': 'Community',
  '/subsidized': 'Subsidized Activities',
  // 质押
  '/singleStaking': 'Stake',
  '/ecologicalReward': 'LP ecological reward',
  '/reward': 'Reward',
  '/redemption': 'Redemption',
  // m-dao
  '/m-dao/home': 'Home',
  '/m-dao/campaign': 'Campaign',
  '/m-dao/referendum': 'Referendum',
  '/m-dao/proposal': 'Proposals',
  '/m-dao/my': 'My',
  // 生态
  '/ecology': 'Ecology'
}

export const ZH = {
  '/': '空投',
  '/login': '登錄',
  '/gameGuild': '公會大廳',
  '/gameLink': '鏈遊',
  '/twins': '分享',
  '/videoList': '視頻',
  '/video': '視頻',
  '/warehouse': '寶藏盒子',
  // 錢包 start
  '/createWallet': '創建錢包',
  '/importWallet': '導入錢包',
  '/tokenDetail': 'Token詳情',
  '/backupPrivateKey': '備份私鑰',
  '/ChangeWalletName': '更換錢包名字',
  '/deleteWallet': '刪除錢包',
  '/transactionDetail': '交易詳情',
  '/transfer': '轉賬',
  '/receive': '收款',
  '/swap': '兌換',
  '/getLP': '獲取LP',
  '/staking': '質押',
  '/redeem': '取回',
  // 錢包 end
  '/gameLinkDetail': '鏈遊詳情',
  '/notice': '公告',
  '/noticeDetail': '公告詳情',
  // 雙生 strat
  '/myFriends': '我的好友',
  '/Invitation': '分享',
  '/browers': '瀏覽器',
  // 彩票
  '/lottery': '彩票',
  '/lotteryHistroy': '彩票',
  '/lotteryRule': '玩法介紹',
  '/myLottery': '我的',
  // 骑士卡
  '/knight': '騎士',
  // 社区
  '/community': '社區',
  '/communityDetail': '社區詳情',
  '/createCommunity': '創建社區',
  '/setCommunity': '設置',
  '/setCommunityName': '社區名稱設置',
  '/setCommunityDec': '社區簡介設置',
  '/setCommunityBtn': '個性按鈕設置',
  '/communitys': '社區',
  '/subsidized': '補貼活動',
  // 质押
  '/singleStaking': '單幣質押',
  '/ecologicalReward': 'LP生態獎勵',
  '/reward': '獎勵',
  '/redemption': '贖回',
  // m-dao
  '/m-dao/home': '首頁',
  '/m-dao/campaign': '理事竟选',
  '/m-dao/referendum': '提案公投',
  '/m-dao/proposal': '社區提案',
  '/m-dao/my': '我的',
  // 生态
  '/ecology': '生態'
}
