<template>
  <VanOverlay :show="lpShow" :z-index="2"
  :class="position === 'top' ? 'top' : 'bottom'"
   @click="closeOption">
   <div class="lp" :class="position === 'top' ? 'top' : 'bottom'">
      <div v-for="(item, index) in miniList"
        :key="item.text"
        @click.stop="miniListClick(item, index)"
      >
        {{ item.text }}
      </div>
    </div>
  </VanOverlay>
</template>

<script>
export default {
  props: {
    position: {
      default () {
        return 'top'
      }
    }
  },
  data () {
    return {
      currentPath: '',
      pathList: ['/', '/notice', '/gameGuild', '/staking', '/twins'],
      lpShow: true
    }
  },
  computed: {
    miniList () {
      return this.$t('tabBar.miniList')
    }
  },
  methods: {
    miniListClick (item, index) {
      this.$emit('miniListClick', item, index)
    },
    // 关闭弹窗
    closeOption () {
      this.$emit('closeOption')
    },
    // 返回一个特定的 DOM 节点，作为挂载的父节点
    getContainer () {
      return document.querySelector('.router-view-body')
    }
  },
  watch: {},
  mounted () {}
}
</script>

<style lang="scss" scoped>
.top {
  top: 60px;
}
.bottom {
  bottom: 55px;
  top: unset;
}
 .lp {
    position: absolute;
    left: 51.8%;
    width: 75px;
    font-size: 12px;
    color: #a2a2a2;
    background-color: #ffffff;
    text-align: center;
    height: 160px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 1;
    cursor: pointer;
    div {
      height: 40px;
      line-height: 38px;
      border-top: 1px solid #f0f0f0;
    }
    &.top{
      top: 0;
    }
    &.bottom {
      bottom: 0;
    }
  }
</style>
