export default {
  changeChain (ctx, chain) {
    ctx.commit('SET_CHAIN', chain)
  },
  changeToken (ctx, authorized) {
    ctx.commit('SET_TOKEN', authorized)
  },
  changeUserInfo (ctx, userInfo) {
    ctx.commit('SET_USERINFO', userInfo)
  },
  changeWebviewUrl (ctx, webviewUrl) {
    ctx.commit('SET_WEBVIEW_URL', webviewUrl)
  },
  changeScene (ctx, scene) {
    ctx.commit('SET_SCENE', scene)
  },
  changeMbLang (ctx, mbLang) {
    ctx.commit('SET_MB_LANG', mbLang)
    ctx.commit('SET_TAB_BAR_LIST', [])
  },
  changeTabBar (ctx, tabBarList) {
    ctx.commit('SET_TAB_BAR_LIST', tabBarList)
  },
  changeAccount (ctx, myAcount) {
    ctx.commit('SET_MY_ACCOUNT', myAcount)
  },
  changeLoading (ctx, isLoading) {
    ctx.commit('SET_LOADING', isLoading)
  },
  changeLoadingIconType (ctx, type) {
    ctx.commit('SET_LOADING_ICON_TYPE', type)
  },
  changeNeedCreateAccount (ctx, needCreateAccount) {
    ctx.commit('SET_NEED_CREATE_ACCOUNT', needCreateAccount)
  }
}
