export const EN = {
  userName: 'Tony',
  level: {
    bronze: 'Bronze',
    silver: 'Silver',
    gold: 'Gold'
  },
  title: 'Airdrop',
  homeImg: '',
  buttonName: 'i am a button',
  buttonDisabled: 'i am a disabled button',
  change: 'change',
  forceValue: 'Hashrate',
  connect: 'Connected with',
  boostValue: 'Commission',
  notice: 'Announce', // 公告
  warehouse: 'Treasure box', // 宝藏盒子
  gepNotice: 'The part unclaimed for more than 48 hours will be included in the DAO Ecological Fund',
  getAll: 'Charge all',
  style: {
    titleName: 'title-name-en',
    homeImg: 'home-img-en'
  },
  text: {
    balance: 'Balance',
    withdraw: 'Withdraw',
    pledge: 'Stake',
    invite: 'Share'
  }
}

export const ZH = {
  userName: '多拉多土著',
  level: {
    bronze: '青銅',
    silver: '白銀',
    gold: '黃金'
  },
  title: '空投',
  homeImg: '',
  buttonName: '我是按鈕',
  buttonDisabled: '我是不能點擊的按鈕',
  change: '切換連接',
  connect: '當前連接',
  forceValue: '原力值',
  boostValue: '助力值',
  notice: '公告', // 公告
  warehouse: '寶藏盒子', // 寶藏盒子
  gepNotice: '超過48小時未領取的部分將計入DAO生態基金',
  getAll: '一鍵收取',
  style: {
    titleName: 'title-name-zh',
    homeImg: 'home-img-zh'
  },
  text: {
    balance: '剩',
    withdraw: '提取',
    pledge: '質押',
    invite: '分享'
  }
}
