export const EN = {
  title: 'Community',
  i18n: 'i18n',
  searchNotice: 'Please enter community name to search',
  communityList: 'Community list',
  createCommunity: 'Create Community',
  allLoaded: 'All loaded',
  modify: 'Modify ',
  communityInfo: {
    founder: 'Founder',
    profile: 'Community profile',
    join: 'Join Community',
    cancel: 'cancel',
    set: 'Set',
    member: 'Members',
    communityPositions: 'Community positions'
  },
  createInfo: {
    name: 'Community name',
    nameNotice: 'Please enter the community name',
    badge: 'Community Badge',
    profile: 'Community profile',
    fee: 'Creation fee',
    create: 'create',
    cancel: 'cancel',
    PersonalityButton: 'Personalized button settings',
    point1:
      '1. When the community reaches 100 people, you will get 1000MEB reward immediately, and every new person will get 10MEB reward after that. ',
    point2:
      '2. Successfully recommending friends to create a community can get a one-time reward of 100MEB. '
  },
  tips: {
    name: 'Please enter the community name',
    dec: 'Please enter a profile, limited to 100 characters',
    logoSize: 'Logo size cannot exceed 100K',
    inputError: 'Please fill in the community information',
    nftRequire: 'Please activate Dao identity first',
    createTips: 'Created successfully, please wait!',
    logoModify: 'Logo modified successfully',
    pleaseInput: 'Please enter '
  },
  dialog: {
    tips: 'Tips',
    createTitle: 'Are you sure you want to create a community?',
    joinTitle: "You can't quit after joining the community, please confirm again!"
  },
  setText: {
    new: 'Please enter a new ',
    nameTips:
      'Please enter the community name, which is limited to 12 characters',
    modifyNow: 'Modify now',
    btnName: 'Button name',
    btnUrl: 'Button link',
    notSet: 'Not set'
  },
  activity: {
    title: 'Subsidized activities',
    contentTitle: 'Activity content',
    activeContent: 'The Meblox platform has opened a new stage of development and fully entered the process of DAO development. <br/> In order to rapidly promote the construction and ecological development of M-DAO infrastructure, the M-DAO dual subsidy program for creating communities and high-quality communities has been launched to accelerate the growth of the M-DAO community and meet new development opportunities',
    activeInfo1: {
      title: 'Subsidized activity 1:',
      content: 'Create your own DAO community on the M-DAO platform, community members are not less than 100 members (purchase knight cards), you can get a subsidy of 3000 MEB, the number of subsidies is 300000 MEB, a total of 100 copies, while stocks last.'
    },
    activeInfo2: {
      title: 'Subsidized activity 2:',
      content: 'Create your own DAO community on the M-DAO platform, the community members are not less than 100 members (purchase knight card), and meb-USDT new pledge is not less than 100,000 MEB, the pledge is not less than 60 days, you can get a subsidy of 20,000 MEB, the number of subsidies is 200000 MEB, a total of 100 copies, while the end is available.'
    },
    member: 'Community Member',
    Conforming: 'Conforming',
    NonConforming: 'Non-Conforming',
    receiveSubsidy: 'Receive',
    remainder: 'remainder',
    portion: 'portion',
    AddedPledge: 'Added Pledge'
  },
  activityBtn: 'Community subsidies'
}

export const ZH = {
  title: '社區',
  i18n: '國際化語言',
  searchNotice: '請輸入社區名稱',
  communityList: '社區列表',
  createCommunity: '創建社區',
  allLoaded: '已全部加載完畢',
  modify: '修改',
  communityInfo: {
    founder: '創始人',
    profile: '社區簡介',
    join: '加入社區',
    cancel: '取消',
    set: '設置',
    member: '成員',
    communityPositions: '社區持倉'
  },
  createInfo: {
    name: '社區名稱',
    nameNotice: '請輸入社區名稱',
    badge: '社區徽章',
    profile: '社區簡介',
    fee: '創建費用',
    create: '創建',
    cancel: '取消',
    PersonalityButton: '個性按鈕設置',
    point1:
      '1、當社區達到100人時立即獲得1000MEB獎勵，此後每新增1人獲得10MEB獎勵。',
    point2: '2、成功推薦好友創建社區可一次性獲得100MEB獎勵。'
  },
  tips: {
    name: '請輸入社區名稱',
    dec: '請輸入簡介，限制50個字符',
    logoSize: 'logo大小不能超過100k',
    inputError: '請填寫社區信息',
    nftRequire: '請先激活dao身份',
    createTips: '創建成功，請等待！',
    logoModify: 'logo修改成功',
    pleaseInput: '請輸入'
  },
  dialog: {
    tips: '提示',
    createTitle: '請確認是否創建社區？',
    joinTitle: '加入社區后不能退出，請再次確認！'
  },
  setText: {
    new: '請輸入新的',
    nameTips: '请输入社区名称，限制12个字符',
    modifyNow: '立即修改',
    btnName: '按鈕名稱',
    btnUrl: '按鈕連接',
    notSet: '未設定'
  },
  activity: {
    title: '補貼活動',
    contentTitle: '活動內容',
    activeContent: 'Meblox平台已开启全新的发展阶段，全面进入DAO化发展进程。 <br />为快速推进M-DAO基础设施建设和生态发展，特启动M-DAO创建社区及优质社区双重补贴方案，加快M-DAO社区壮大，迎接新的发展机遇',
    activeInfo1: {
      title: '補貼活動一：',
      content: '在M-DAO平台創建自己的DAO社區，社區成員不低於100個成員（購買騎士卡），即可獲得補貼3000MEB，此次補貼數量為300000MEB，共計100份，領完即止。'
    },
    activeInfo2: {
      title: '補貼活動二：',
      content: '在M-DAO平台創建自己的DAO社區，社區成員不低於100個成員（購買騎士卡），且MEB-USDT新增質押不低於10萬MEB，質押不低於60天，即可獲得補貼20000MEB，此次補貼數量為2000000MEB，共計100份，領完即止。 '
    },
    member: '社區成員',
    Conforming: '已達標',
    NonConforming: '未達標',
    receiveSubsidy: '領取補貼',
    remainder: '剩餘補貼',
    portion: '份',
    AddedPledge: '新增質押'
  },
  activityBtn: '社區補貼'
}
