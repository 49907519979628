export const EN = {
  title: 'wallet',
  myCapital: 'My Capital',
  transfer: 'Trans',
  receive: 'Receive',
  lpList: [
    // {
    //   id: 1,
    //   icon: require('@/static/img/wallet/icon1.png'),
    //   text: 'Swap',
    //   path: '/swap'
    // },
    {
      id: 2,
      icon: '',
      text: 'GetLP',
      path: '/getLP'
    },
    {
      id: 3,
      icon: '',
      text: 'StakeLP',
      path: '/staking'
    },
    {
      id: 4,
      icon: '',
      text: 'UnStakeLP',
      path: '/unStaking'
    }
  ],
  capitalDetail: 'Capital Detail',
  walletDetail: {
    title: 'Wallet Detail'
  },
  createWalletTitle: {
    privateKeyTitle: 'Please enter the private key',
    inputPlaceholderName: 'Please enter a nickname',
    inputPlaceholderPassWord: 'Please enter your wallet password',
    inputPlaceholderPassWordAgin: 'Please enter your wallet password again',
    inputPlaceholderPrivateKey: 'Please enter the Activation code',
    nameTitle: 'Set up a nickname',
    walletPassWordTitle: 'Set wallet password',
    checkPassWordTitle: 'Reconfirm your wallet password',
    inviteCodeTitle: 'Please Enter the Activation Code(require)',
    cancel: 'cancel'
  },
  // 创建钱包
  createWalletLimitTips: {
    privateKeyCheck: '*The private key information is incorrect',
    privateKeyLimit: 'Only support binance smart Chain (BSC)',
    nameLimit: '*The nickname is too long. Enter a maximum of 12 characters',
    passWordLimit: '*The password is too long. The maximum length is 20 characters',
    passWordCheck: '*The two passwords are inconsistent',
    inviteCodeCheck: 'Activation Code'
  },
  // 收款
  WalletAddress: 'Wallet Address'
}

export const ZH = {
  title: '錢包',
  myCapital: '我的資產',
  transfer: '轉賬',
  receive: '收款',
  lpList: [
    // {
    //   id: 1,
    //   icon: require('@/static/img/wallet/icon1.png'),
    //   text: '兌換',
    //   path: '/swap'
    // },
    {
      id: 2,
      icon: '',
      text: '獲取LP',
      path: '/getLP'
    },
    {
      id: 3,
      icon: '',
      text: '質押LP',
      path: '/staking'
    },
    {
      id: 4,
      icon: '',
      text: '取回LP',
      path: '/unStaking'
    }
  ],
  capitalDetail: '資產詳情',
  walletDetail: {
    title: '錢包詳情'
  },
  createWalletTitle: {
    privateKeyTitle: '請輸入私鑰',
    inputPlaceholderName: '請輸入昵稱',
    inputPlaceholderPassWord: '請輸入錢包密碼',
    inputPlaceholderPassWordAgin: '請再次輸入錢包密碼',
    inputPlaceholderPrivateKey: '請輸入啟動碼',
    nameTitle: '設置昵稱',
    walletPassWordTitle: '設置錢包密碼',
    checkPassWordTitle: '再次確認錢包密碼',
    inviteCodeTitle: '請輸入啟動碼（必填）',
    cancel: '取消'
  },
  // 創建錢包
  createWalletLimitTips: {
    privateKeyCheck: '*私鑰信息錯誤',
    privateKeyLimit: '僅支持幣安智能鏈（BSC）',
    nameLimit: '*昵稱過長，輸入最長限製12字符',
    passWordLimit: '*密碼過長，輸入最長限製20字符',
    passWordCheck: '*兩次密碼輸入不一致',
    inviteCodeCheck: '*啟動碼'
  },
  WalletAddress: '錢包地址'
}
