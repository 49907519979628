export const EN = {
  title: 'twins',
  headerTitle: 'Achievement',
  twinBoostValue: 'Commission',
  twinCommissionAward: 'Boost reward',
  twinrecommendationAward: 'Ability reward',
  twinManagementAward: 'Contribution reward',
  Hashrate: 'Hashrate',
  friendHashraet: 'Effective Hashrate', // Friend\'s Hashrate
  a: 'Common',
  b: 'Private',
  person: 'person',
  aValue: 'α value',
  bValue: 'β value',
  myFriend: 'Friends',
  inviteFriends: 'Share',
  friendValue: 'Friend α value',
  ineffect: 'In Effect',
  numberPeople: 'Persons',
  aInviteCode: 'Common code',
  bInviteCode: 'Private code',
  appLink: 'Link',
  copy: 'Copy Success',
  Invitee: 'Applicant',
  inviteCode: 'inviteCode'
}

export const ZH = {
  title: '雙生',
  titleImg: '',
  // titleImg: require('@/assets/title/title_shuangsheng_cn.png'),
  headerTitle: '雙生成就',
  twinBoostValue: '助力值',
  Hashrate: '原力值',
  friendHashraet: '有效原力值', // 好友原力值
  twinCommissionAward: '助力貢獻',
  twinrecommendationAward: '原力貢獻',
  twinManagementAward: '綜合貢獻',
  myFriend: '我的好友',
  inviteFriends: '分享好友',
  a: '公域',
  b: '私域',
  person: '人數',
  aValue: 'α值',
  bValue: 'β值',
  friendValue: '好友α值',
  ineffect: '生效中',
  numberPeople: '人數',
  aInviteCode: '公碼',
  bInviteCode: '私碼',
  appLink: '邀請鏈接',
  copy: '復製成功',
  Invitee: '申請人',
  inviteCode: '邀請碼'
}
