<template>
  <div class="tab-bar">
    <!-- <div class="lp" v-if="lpShow">
      <div v-for="(item, index) in miniList" :key="item.text" @click="miniListClick(item,index)">{{item.text}}</div>
    </div> -->
    <div class="tab-group">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="tab-bar-item"
        @click="onChange(item, index)"
      >
        <!-- <img class="tab_img" :src="selected === index ? item.selectedIconPath : item.iconPath" mode="widthFix" /> -->
        <div
          class="iconfont"
          :class="selected === index ? `${item.icon} active ` : `${item.icon} `"
        ></div>
        <div class="tab_text" :class="{ active: selected === index }">
          {{ item.text }}
        </div>
      </div>
    </div>
    <LpOotion
      v-if="lpShow"
      position="bottom"
      @closeOption="closeOption"
      @miniListClick="miniListClick"
    />
  </div>
</template>

<script>
import LpOotion from './LpOption.vue'
export default {
  components: { LpOotion },
  props: {
    selected: {
      default () {
        return 0
      }
    }
  },
  data () {
    return {
      lpShow: false
    }
  },
  computed: {
    list () {
      return this.$t('tabBar.list')
    },
    miniList () {
      return this.$t('tabBar.miniList')
    }
  },
  methods: {
    onChange (item, index) {
      if (item.url === 'comingSoon') {
        return this.$toast(this.$t('common.ComingSoon'))
      }
      if (item.url === '/community') {
        return this.$toast(this.$t('common.updating'))
      }
      this.$gbUtils.handleToPage(item.url)
      // if (item.url !== '/staking') {
      //   this.$gbUtils.handleToPage(item.url)
      //   // document.title = item.text
      //   this.lpShow = false
      // } else {
      //   this.lpShow = !this.lpShow
      // }
    },
    miniListClick (item, index) {
      this.lpShow = false
      if (index === 0 || index === 3) {
        window.location = item.url
        return
      }
      this.$gbUtils.handleToPage(item.url)
      // document.title = item.text
    },
    // 弹窗显隐
    closeOption () {
      this.lpShow = !this.lpShow
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px;
  background: #fff;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 999;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
}
.lp {
  position: absolute;
  bottom: 55px;
  margin-left: 52%;
  width: 20%;
  font-size: 12px;
  color: #a2a2a2;
  background-color: #ffffff;
  text-align: center;
  height: 160px;
  z-index: 22 !important;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
  div {
    height: 40px;
    line-height: 38px;
    border-top: 1px solid #f0f0f0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
}
.iconfont {
  font-size: 23px;
  color: #a2a2a2;
}
.tab-group {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}
.tab-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.tab-bar-item {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.tab_img {
  // width: 20px;
  height: 20px;
  width: auto;
}

.tab_text {
  font-size: 10px;
  color: #a2a2a2;
}
.active {
  color: #25e2b1;
}
</style>
