import Vue from 'vue'

import { Button, Swipe, SwipeItem, Icon, Popup, Uploader, Tab, Tabs, Form, Field, Empty, Dialog, Progress, DropdownMenu, DropdownItem, Toast, Sticky, PullRefresh, List, Overlay, NoticeBar, Loading, Skeleton } from 'vant'
Vue.use(Button)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Icon)
Vue.use(Popup)
Vue.use(Uploader)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Form)
Vue.use(Field)
Vue.use(Empty)
Vue.use(Dialog)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Toast)
Vue.use(Sticky)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Progress)
Vue.use(Overlay)
Vue.use(NoticeBar)
Vue.use(Loading)
Vue.use(Skeleton)
