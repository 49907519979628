export const EN = {
  forceValue: 'Hashrate',
  powerValue: 'Commission',
  numberFriends: 'friends',
  aWorld: 'Private',
  bWorld: 'Common',
  inactive: 'inactive',
  savePosters: 'Save the posters',
  friend: 'Friend'
}

export const ZH = {
  forceValue: '原力值',
  powerValue: '助力值',
  numberFriends: '好友人數',
  aWorld: '公域',
  bWorld: '私域',
  inactive: '未激活',
  savePosters: '保存海報',
  friend: '好友'
}
