<template>
  <div class="wrapper">
    <!-- v-if="$route.meta.haveNavigationBar" -->
    <div class="navigation-bar" :class="{'no-shadow': path === '/'}">
      <div class="iconfont icon-jiantou_xiangzuo arrow" alt="" @click="handleBack"
        v-if="$route.meta.isBack" />
      <!-- 新版ui无img
      <img :src="titleImg" class="navigation-title" v-if="titleImg && false" /> -->
      <span class="navigation-title text-title">{{ homeTitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      titleImg: '',
      // titleText: '',
      path: ''
    }
  },
  computed: {
    // titleText () {
    //   return this.$t(`navigationBar.${this.path}`)
    // },
    homeTitle () {
      return this.$t(`navigationBar.${this.path}`)
    },
    miniList () {
      return this.$t('tabBar.miniList')
    }
  },
  methods: {
    handleBack () {
      this.$router.back(1)
    }
  },
  watch: {
    // homeTitle (val) {
    //   if (val) {
    //     document.title = val
    //   } else {
    //     document.title = 'Meblox'
    //   }
    // },
    $route (to, from) {
      this.path = to.path
      // this.titleText = this.$t(`navigationBar.${to.path}`)
      // if (this.$t(`navigationBar.${to.path}`) !== 'Stake') {
      //   this.titleText = this.$t(`navigationBar.${to.path}`)
      // } else {
      //   if (parseInt(to.query.index) === 0) {
      //     this.titleText = this.miniList[0].text
      //   } else if (parseInt(to.query.index) === 1) {
      //     this.titleText = this.miniList[1].text
      //   } else if (parseInt(to.query.index) === 2) {
      //     this.titleText = this.miniList[2].text
      //   } else {
      //     this.titleText = this.miniList[3].text
      //   }
      // }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.navigation-bar {
  // display: none;
  position: relative;
  box-sizing: border-box;
  // padding-bottom: 50px;
  width: 100%;
  height: 55px;
  background-color: #fff;
  background-size: 100% 100%;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 0;
  .navigation-title {
    position: absolute;
    top: 17px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    color: #3a3a3a;
    font-weight: bold;
  }
  .text-title {
    margin-top: 5px;
  }
  .arrow {
    position: absolute;
    top: 22px;
    left: 15px;
    cursor: pointer;
    font-size: 32px;
    color: #2A2B33;
  }
}
// 无圆角和阴影
.no-shadow {
  box-shadow: none;
  border-radius: 0;
}
</style>
