export const EN = {
  noneData: 'None Data',
  cancel: 'Cancel',
  confirm: 'Confirm',
  detail: 'Detail',
  Input: 'Input',
  Balance: 'Balance',
  Price: 'Price',
  ConnectWallet: 'Connect Wallet',
  Apporve: 'Apporve',
  InviteCode: 'Activation Code',
  Waiting: 'Waiting',
  Signature: 'Signature',
  stakeTips: {
    Apporve: 'Apporve LP',
    Stake: 'Staking LP',
    Redeem: 'Redeem LP',
    valueMin: 'please enter more than {value}',
    inputNone: 'please input value',
    insufficient: 'The balance is insufficient',
    getLp: 'Get LP first'
  },
  deposeTips: {
    title: 'Deposit 501USDT+{needMeb}MEB for the first pledge',
    rules:
      '*Calculation rule: USDT fixed value, MEB quantity = 501/MEB unit price',
    successTips:
      '*The force will be refreshed about 10 minutes after the pledge is successful'
  },
  withdrawTips: 'Click too fast',
  ComingSoon: 'Coming soon',
  pledgeAmount: ' pledge amount',
  updating: 'Upgrading, stay tuned'
}

export const ZH = {
  noneData: '暫無數據',
  cancel: '取消',
  confirm: '確認',
  detail: '詳情',
  Input: '輸入',
  Balance: '剩餘',
  Price: '價格',
  ConnectWallet: '鏈接',
  Apporve: '執行',
  InviteCode: '激活碼',
  Waiting: '等待',
  Signature: '簽名',
  stakeTips: {
    Apporve: '執行LP',
    Stake: '質押LP',
    Redeem: '取回LP',
    valueMin: '質押最小值為{value}',
    inputNone: '請輸入質押量',
    insufficient: '剩不足',
    getLp: '請先獲取 LP'
  },
  deposeTips: {
    title: '*首次質押需存入501USDT+{needMeb}MEB',
    rules: '計算規則：USDT固定數值，MEB數量 = 501/MEB單價',
    successTips: '*質押成功後10分鐘左右會刷新原力值'
  },
  withdrawTips: '點擊過快',
  ComingSoon: '即將上線',
  pledgeAmount: '質押量',
  updating: '升級中，敬請期待'
}
