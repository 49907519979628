import request from '@/utils/request'

// 用户注册
export const queryUserRegister = (params) => {
  return request('POST', '/api/app/customer/register', params, false)
}
// 获取验证码
export const queryGetCode = (params) => {
  return request('GET', '/api/app/customer/get_code', params, false)
}
// 用户登录
export const queryLogin = (params) => {
  return request('POST', '/api/app/customer/login', params, false)
}
// 密码重置
export const querySetCode = (params) => {
  return request('POST', '/api/app/customer/reset_pwd', params, false)
}

// 用户登录
export const queryWalletLogin = (params) => {
  return request('POST', '/app/wallet/login', params, false)
}

// 钱包注册账号
export const queryWalletRegister = (params) => {
  return request('POST', '/app/wallet/create_wallet', params)
}
