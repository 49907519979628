import contractsInfo from '../utils/contractsInfo'
// pancake 交易会收取0.0025的手续费
export default {
  // 【完整生产环境】
  server: 'https://app.meblox.io/api',
  imageUrl: '', // OSS地址，暂时不启用
  provider: 'https://mainnet.infura.io/v3/79672fa72d88459e9f530742628392aa', // 公链地址
  contractsInfo: contractsInfo, // pancake router && token合约地址
  factoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73', // pancake factory
  lockAddress: '0x9Ed0064F96196130b41156aC49Cc59D4F5086340', // 正式锁仓合约
  singleStakeAddress: '0xa9be808649db1C0cDb22788B8150a48Eb55C890E', // 单币质押合约
  rewardAddress: '0x949Ea644969E3bb1b64BC519977146cBaf81bd7E', // LP生态奖励质押合约
  mebMusdAddress: '0xf995e22c355c04c24E06Ac8253b82bcAb722f0B4', // LP生态奖励质押合约 meb-musds
  timeOption: [{
    name: 360,
    percent: 10,
    lockSecond: 31104000
  },
  {
    name: 180,
    percent: 4,
    lockSecond: 15552000
  },
  {
    name: 60,
    percent: 1,
    lockSecond: 5184000
  }],
  pancakeUrl: 'https://pancakeswap.finance/',
  pancakeAdd: `add/${contractsInfo.tokenUSDTContract.address}/${contractsInfo.tokenGEPContract.address}`,
  pancakeAddMebMusd: `add/${contractsInfo.tokenGEPContract.address}/${contractsInfo.tokenMUSDContract.address}`,
  pancakePool: 'liquidity',
  bscBrowser: 'https://bscscan.com',
  // 币安链ID
  ethChainNumberId: 56,
  // 币安链配置
  chainConfig: {
    chainId: '0x38',
    chainName: 'BSC Main',
    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18
    },
    blockExplorerUrls: ['https://bscscan.com/']
  }
}
