export const EN = {
  level: {
    toActive: 'Go to Activate',
    detail: 'Detail',
    leval_0: 'DAO Identity',
    leval_1: 'Knight',
    leval_2: 'Baron',
    leval_3: 'Viscount',
    leval_4: 'Earl',
    leval_5: 'Marquis',
    leval_6: 'Duke',
    tips: 'Promotion Criteria: Successfully invite six knighthoods with a rank greater than or equal to your current level',
    buyTips: 'Activation successful, please wait'
  },
  buy: {
    price: 'Exchange',
    purchaseActivation: 'Confirm activation',
    tips: 'Holding an NFT Knight Card allows you to become an DAO member',
    remainingQuantity: 'Remaining quantity'
  },
  privileges: {
    title: 'DAO Identity Privileges:',
    point1:
      '1. Qualified to participate in online and offline learning activities of "Preach DAO Academy".',
    point2:
      '2. The right to use M-DAO organization facilities around the world and discount cards of cooperative merchants, etc.',
    point3:
      '3. Airdrop right and priority to participate in neutron ecological project of launch platform.',
    point4:
      '4. Preferential rights and experience rights of peripheral products and derivatives of the whole M-DAO ecosystem.',
    point5:
      '5. Participate in exchange and study tour activities and social activities organized by M-DAO.',
    point6:
      '6. Transfer and transaction functions will be opened later, and the value of cards will continue to increase.',
    point7:
      '7. Co-construction, co-governance, sharing, more rights and benefits!',
    point8: '8. Refer a friend to buy a knight card and get 10MEB reward'
  }
}

export const ZH = {
  level: {
    toActive: '去激活',
    detail: '詳情',
    leval_0: 'DAO身份',
    leval_1: '騎士',
    leval_2: '男爵',
    leval_3: '子爵',
    leval_4: '伯爵',
    leval_5: '侯爵',
    leval_6: '公爵',
    tips: '晉陞條件：成功邀請六個爵位等級大於等於您的當前等級',
    buyTips: '激活成功，請等待'
  },
  buy: {
    price: '兌換',
    purchaseActivation: '確認激活',
    tips: '持有一張NFT騎士卡即可成為DAO成員',
    remainingQuantity: '剩餘數量'
  },
  privileges: {
    title: 'DAO身份特權：',
    point1: '1、獲得“布DAO學苑”線上、線下學習活動的參與資格。',
    point2: '2、全球各地M-DAO組織設施的使用權及合作商家的優惠卡券等。',
    point3: '3、發射平臺中子生態項目的空投權及優先參與權。',
    point4: '4、整個M-DAO生態的周邊產品及衍生品的優惠權及體驗權。',
    point5: '5、參與M-DAO組織的交流遊學活動、社交活動等權益。',
    point6: '6、後續將開通轉讓及交易功能，卡片價值將不斷提升。',
    point7: '7、共建、共治、共享，權益多多，福利多多！',
    point8: '8、推薦好友購買騎士卡，獲得10MEB獎勵'
  }
}
