<template>
  <div id="app" :class="mbLang === 'EN' ? 'ENFONT' : 'CNFONT'">
    <!-- 登录 -->
    <HeaderBar v-if="!mobileFlag && false" ref="navigationBar"></HeaderBar>
    <Login
      v-if="loginPopShow && false"
      @hidePop="hidePop"
      @loginSuccess="loginSuccess"
    />
    <div :class="pcClass">
      <div class="router-view">
        <NavigationBar ref="navigationBar" v-if="!mobileFlag" />
        <router-view
          @change="change"
          @changeLoading="changeLoading"
          @changeLoadingIconType="changeLoadingIconType"
          @setLoadingMessage="setLoadingMessage"
          :class="{ 'pc-router': !mobileFlag }"
          v-if="routerViewShow"
        ></router-view>
      </div>
    </div>
    <BottomTabBar
      v-if="$route.meta.haveBottomTabBar && mobileFlag && false"
      :selected="$route.meta.selected"
    />
    <Loading v-if="isLoading" @setLoadingMessage="setLoadingMessage">
      <div class="loading-text" v-if="loadinMessage">{{ loadinMessage }}</div>
    </Loading>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import NavigationBar from './components/common/NavigationBar.vue'
import Login from './components/common/Login.vue'
import HeaderBar from './components/common/HeaderBar.vue'
import BottomTabBar from './components/common/BottomTabBar.vue'
export default {
  components: {
    NavigationBar,
    Login,
    HeaderBar,
    BottomTabBar
  },
  data () {
    return {
      pcClass: '', // pc-style
      loginPopShow: false, // 登录
      routerViewShow: true, // 组件显示
      loadinMessage: '', // 加载提示信息
      tronWeb: null,
      walletAddress: null
    }
  },
  computed: {
    ...mapState(['mbLang', 'myAcount', 'authorized', 'needCreateAccount', 'isLoading']),
    mobileFlag () {
      return this.isMobile()
    }
  },
  watch: {
    authorized (val, oldVal) {
      if (!val) {
        this.judgeLogin()
      }
    },
    myAcount (val, oldVal) {
      if (!val) {
        this.judgeLogin()
      }
    },
    needCreateAccount (val) {
      if (val) {
        this.$nextTick(() => {
          this.loginPopShow = true
        })
      }
    },
    isLoading (val) {
      if (!val) this.loadinMessage = ''
    }
  },
  methods: {
    ...mapActions(['changeChain', 'changeToken', 'changeAccount', 'changeLoading', 'changeLoadingIconType']),
    init () {
      this.judgeAgent()
      this.listenWallet()
      this.getMalletAmount()
    },
    // 获取当前链ID
    async getChain () {
      const resp = await this.$web3.getChainId()
      if (resp) {
        this.changeChain(resp)
      }
    },
    // 获取当前钱包账户
    async getMalletAmount () {
      const resp = await this.$web3.connectWallet()
      if (!resp.success) {
        return this.$toast(resp.message)
      }
    },
    // 钱包监听
    listenWallet () {
      // 账号切换
      this.$web3.ethereum.on('accountsChanged', accounts => {
        console.log('accountsChanged')
        this.changeToken()
        this.changeAccount(accounts[0])
        this.loginPopShow = false
        this.$nextTick(() => {
          this.loginPopShow = true
        })
      })
      // 断开链接
      this.$web3.ethereum.on('disconnect', res => {
        console.log('disconnect')
        this.changeToken()
        this.changeAccount()
        // this.toLogin()
      })
    },
    // 判断是否登录
    judgeLogin () {
      if (!this.authorized || !this.myAcount) {
        // console.log('no account')
        // console.log('lthis.oginPopShow', this.loginPopShow)
        if (this.loginPopShow) return
        this.loginPopShow = true
      }
    },
    // 隐藏登录弹窗
    hidePop () {
      this.loginPopShow = false
    },
    // 登录成功
    loginSuccess () {
      this.routerViewShow = false
      this.loginPopShow = false
      this.$nextTick(() => {
        this.routerViewShow = true
      })
    },
    toLogin () {
      const path = this.$router.history.current.path
      if (path !== '/') {
        this.$router.replace('/').catch(err => { console.log(err) })
      }
    },
    // 页面切换
    change (val) {
      // if (this.mobileFlag) return
      // this.$refs.navigationBar.titleText = val
      // document.title = val
    },
    // 判断是否登录
    judgeAgent () {
      if (this.isMobile()) {
        console.log('mobile phone')
        this.pcClass = ''
      } else {
        console.log('pc')
        this.pcClass = 'pc-style'
      }
    },
    // 判断当前终端
    isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    // 获取加载信息
    setLoadingMessage (message) {
      this.loadinMessage = message
    }

    // // 查询钱包余额
    // async getBalance () {
    //   // 当前连接的钱包地址获取 window.tronWeb.defaultAddress.base58
    //   var balance = await this.tronWeb.trx.getBalance(this.walletAddress)
    //   console.log('balance=', balance)
    // },
    // async getAccount () {
    //   const account = await this.tronWeb.trx.getNodeInfo()
    //   console.log('account', account)
    // }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss">
body {
  font-variant-east-asian: traditional;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  color: transparent;
}
#app {
  background-size: 100% auto;
  background-repeat: repeat-y;
  min-height: 100vh;
  // font-family: Source Han Sans SC, PingFangSC-Regular, sans-serif;
  .input-box {
    text-align: center;
    height: 50px;
    border-radius: 15px;
    background: #f0f0f0;
    ::-webkit-input-placeholder {
      color: #818086;
      font-size: 15px;
    }
  }
  .close-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: #24c294;
    border-radius: 10px;
    text-align: center;
    line-height: 20px;
    color: #fff;
  }
}

.ENFONT {
  font-family: "Helvetica", "Roboto", "segoe" "Trebuchet MS",
    "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}
.CNFONT {
  font-family: "Source Han Sans SC", "PingFangSC-Regular", sans-serif;
}
.padding-layout {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px 10px 15px;
}
// 移动端子页面
.router-view {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
  box-sizing: border-box;
  // tab页
  .tab-page {
    padding-bottom: 80px !important;
    box-sizing: border-box;
  }
}
// pc端
.pc-style {
  opacity: 1;
  position: relative;
  background: #e8eef4;
  height: calc(100vh - 60px);
  // height: 100vh;
  line-height: 1em;
  vertical-align: middle;
  display: flex;
  .router-view {
    width: 100%;
    background-color: #f8f8f8;
    min-width: 375px;
    max-width: 532px;
    min-height: calc(100vh - 120px) !important;
    height: calc(100vh - 120px) !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    .tab-page {
      padding-bottom: 0px;
      box-sizing: border-box;
      background-color: #f8f8f8;
    }
  }
  .loadingWrap {
    /deep/ .login-img {
      width: 100px !important;
      height: auto;
    }
  }
  // pc端router-view
  .pc-router {
    height: calc(100% - 55px) !important;
    overflow: scroll;
  }
}
.loading-text {
  text-align: center;
  color: #66eab9;
  font-size: 14px;
  margin-top: 10px;
}
</style>
