import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index'
import { ZH as navigationBarZH, EN as navigationBarEn } from './navigationBar'
import { ZH as tabBarZH, EN as tabBarEn } from './tabBar'
import { ZH as commonZH, EN as commonEn } from './common'
import { ZH as homeZh, EN as homeEn } from './home'
import { ZH as twinsZh, EN as twinsEn } from './twins'
import { ZH as walletZh, EN as walletEn } from './wallet'
import { ZH as MyFriendsZh, EN as MyFriendsEn } from './myFriends'
import { ZH as wareHouseZn, EN as wareHouseEn } from './wareHouse'
import { ZH as stakingZh, EN as stakingEn } from './staking'
import { ZH as browersZh, EN as browersEn } from './browers'
import { ZH as lotteryZh, EN as lotteryEn } from './lottery'
import { ZH as knightZh, EN as knightEn } from './knight'
import { ZH as communityZh, EN as communityEn } from './community'
import { ZH as ecologyZh, EN as ecologyEn } from './ecology'
import { ZH as ecologicalRewardZh, EN as ecologicalRewardEn } from './ecologicalReward'
Vue.use(VueI18n)
var mbLang = store.state.mbLang
if (!mbLang) {
  store.commit('SET_MB_LANG', 'ZH')
  mbLang = 'ZH'
}

const EN = {
  navigationBar: navigationBarEn,
  tabBar: tabBarEn,
  common: commonEn,
  home: homeEn,
  twins: twinsEn,
  wallet: walletEn,
  myFriends: MyFriendsEn,
  wareHouse: wareHouseEn,
  staking: stakingEn,
  browers: browersEn,
  lottery: lotteryEn,
  knight: knightEn,
  community: communityEn,
  ecology: ecologyEn,
  ecologicalReward: ecologicalRewardEn
}

const ZH = {
  navigationBar: navigationBarZH,
  tabBar: tabBarZH,
  common: commonZH,
  home: homeZh,
  twins: twinsZh,
  wallet: walletZh,
  myFriends: MyFriendsZh,
  wareHouse: wareHouseZn,
  staking: stakingZh,
  browers: browersZh,
  lottery: lotteryZh,
  knight: knightZh,
  community: communityZh,
  ecology: ecologyZh,
  ecologicalReward: ecologicalRewardZh
}

const i18n = new VueI18n({
  locale: mbLang,
  silentTranslationWarn: true,
  messages: {
    EN: EN,
    ZH: ZH
  }
})
export default i18n
