import devpConfig from './devp.config'
import prodConfig from './prod.config'
import testConfig from './test.config'
import aliConfig from './ali.config'
import preConfig from './pre.config'

let config
switch (process.env.RELEASE_ENV) {
  case 'prod':
    config = prodConfig
    break
  case 'test':
    console.log('now env', process.env.RELEASE_ENV)
    config = testConfig
    break
  case 'ali':
    config = aliConfig
    break
  case 'pre':
    console.log('now env', process.env.RELEASE_ENV)
    config = preConfig
    break
  default:
    console.log('now env: dev')
    config = devpConfig
    config = testConfig
    // config = preConfig
    config = prodConfig
  // 开启跨域，调试正式服的时候需要打开
  // config.server = '/mbApi'
}

export default {
  ...config
}
