import store from '@/store'
let tronWeb = window.tronWeb
console.log('window.tronWeb', window.tronWeb)
// 统一错误返回
const failResult = (message) => ({ success: false, message })
// 统一成功返回
const successResult = (result) => ({ success: true, result })

// 初始化无法获取tronWeb对象 需定时器
const Inval = setInterval(() => {
  tronWeb = window.tronWeb ? window.tronWeb : ''
  // 当获取到地址的时候就关掉定时器
  // console.log('defaultAddress', tronWeb)
  if (tronWeb) {
    window.clearInterval(Inval)
  }
}, 1000)
/**
* 连接TronLink，获取账号
*/
const connectWallet = () => {
  // 判断用户是否安装TronLink钱包插件
  // console.log('1', tronWeb)
  if (!tronWeb) {
    const message = {
      message: 'No Wallet!'
    }
    return failResult(message)
  }
  try {
    const accounts = tronWeb.defaultAddress.base58
    console.log('accounts', accounts)
    // ethereum.enable() // 旧的API
    store.commit('SET_MY_ACCOUNT', accounts) // 暂存account
    return successResult(accounts)
  } catch (reason) {
    alert(reason)
    // reason.code => 4001 用户拒绝
    const message = reason.message || 'There was a problem signing you in'
    // Toast(reason.message)
    return failResult(message)
  }
}

/**
* 签名数据
* @account 需要签名的账户
* @data 需要签名的数据
* @privatekey 私钥
* @return 签名后的数据
*/
const signData = async (data) => {
  if (!data) return failResult('签名数据不可为空')
  try {
    const msg = hexData(data).result
    const signedData = await tronWeb.trx.sign(msg)
    console.log('signedData', signedData)
    return successResult(signedData)
  } catch (error) {
    return failResult(error)
  }
}

/*
* 获取HEX数据
*/
const hexData = (data) => {
  try {
    return successResult(tronWeb.toHex(data))
  } catch (error) {
    return failResult(error)
  }
}

/**
* 获取账户信息
* @myAcount 账户地址
* @return 账户余额,地址
*/
const getAccount = async (myAcount) => {
  myAcount = store.state.myAcount
  try {
    const account = await tronWeb.trx.getAccount(myAcount)
    return successResult(account)
  } catch (error) {
    return failResult(error)
  }
}

/**
* 询账户trx余额
*@myAcount 当前账户地址
*/
const getBalance = async (myAcount) => {
  myAcount = store.state.myAcount
  try {
    var balance = await tronWeb.trx.getBalance(myAcount)
    // balance = balance.toNumber() / Math.pow(10, 18)
    const trx = fromSun(balance)
    return successResult(trx)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 返回最新区块信息
 */
const getCurrentBlock = async () => {
  try {
    var block = await tronWeb.trx.getCurrentBlock()
    return successResult(block)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 返回区块信息
 * @height 区块高度或ID
 */
const getBlock = async (blockInfo) => {
  try {
    var block = await tronWeb.trx.getBlock(blockInfo)
    return successResult(block)
  } catch (error) {
    return failResult(error)
  }
}
/**
 * 单位转换，将sun等价转换为trx。 （1sun = 0.000001 TRX）
 * @param  sun sun
 * @returns trx
 */

const fromSun = (sun) => {
  try {
    var trx = tronWeb.fromSun(sun)
    return successResult(trx)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 单位转换，将TRX等价转换为SUN。 （1sun = 0.000001 TRX）
 * @param  trx trx
 * @returns trx
 */

const toSun = (trx) => {
  try {
    var sun = tronWeb.toSun(trx)
    return successResult(sun)
  } catch (error) {
    return failResult(error)
  }
}
export default {
  connectWallet,
  signData,
  getAccount,
  getBalance,
  getCurrentBlock,
  getBlock,
  hexData,
  fromSun,
  toSun
}
