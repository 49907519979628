import config from '@/config/app.config'
export const EN = {
  list: [{
    url: '/',
    iconPath: require('@/assets/tabBar/mineral_a.png'),
    selectedIconPath: require('@/assets/tabBar/mineral_b.png'),
    text: 'Airdrop',
    icon: 'icon-liwu1'
  },
  // {
  //   url: '/notice',
  //   iconPath: require('@/assets/tabBar/notice_a.png'),
  //   selectedIconPath: require('@/assets/tabBar/notice_b.png'),
  //   text: 'Announce',
  //   icon: 'icon-gonggao'
  // },
  {
    url: '/community',
    iconPath: require('@/assets/tabBar/gameGuild_a.png'),
    selectedIconPath: require('@/assets/tabBar/gameGuild_b.png'),
    text: 'Community',
    icon: 'icon-tuanduichengyuan'
  },
  // {
  //   url: '/browers',
  //   iconPath: require('@/assets/tabBar/invitation_a.png'),
  //   selectedIconPath: require('@/assets/tabBar/invitaion_b.png'),
  //   text: 'browser',
  //   icon: 'icon-liulanqi2'
  // },
  {
    url: '/ecologicalRewardMusd',
    iconPath: require('@/assets/tabBar/pledge_a.png'),
    selectedIconPath: require('@/assets/tabBar/pledge_b.png'),
    text: 'Stake',
    icon: 'icon-zhiyajiezhiya'
  },
  // {
  //   url: '/ecology',
  //   text: 'Ecology',
  //   icon: 'icon-shengtaiquan'
  // },
  {
    url: '/twins',
    iconPath: require('@/assets/tabBar/invitation_a.png'),
    selectedIconPath: require('@/assets/tabBar/invitaion_b.png'),
    text: 'Share',
    icon: 'icon-yaoqing'
  }],
  miniList: [
    {
      url: `${config.pancakeUrl + config.pancakeAdd}`,
      text: 'Get LP'
    },
    {
      url: '/staking?index=1',
      text: 'Stake LP'
    },
    {
      url: '/staking?index=2',
      text: 'Redeem LP'
    },
    {
      url: `${config.pancakeUrl + config.pancakePool}`,
      text: 'Remove'
    }
  ],
  daoList: [
    {
      url: '/m-dao/home',
      text: 'Home',
      icon: 'icon-a-lujing185'
    },
    {
      url: '/m-dao/campaign',
      text: 'Campaign',
      icon: 'icon-a-zu208'
    },
    {
      url: '/m-dao/referendum',
      text: 'Referendum',
      icon: 'icon-a-zu207'
    },
    {
      url: '/m-dao/proposal',
      text: 'Proposals',
      icon: 'icon-a-lujing193'
    },
    {
      url: '/m-dao/my',
      text: 'My',
      icon: 'icon-a-lujing194'
    }
  ]
}

export const ZH = {
  list: [{
    url: '/',
    iconPath: require('@/assets/tabBar/mineral_a.png'),
    selectedIconPath: require('@/assets/tabBar/mineral_b.png'),
    text: '空投',
    icon: 'icon-liwu1'
  },
  // {
  //   url: '/notice',
  //   iconPath: require('@/assets/tabBar/notice_a.png'),
  //   selectedIconPath: require('@/assets/tabBar/notice_b.png'),
  //   text: '公告',
  //   icon: 'icon-gonggao'
  // },
  {
    url: '/community',
    iconPath: require('@/assets/tabBar/gameGuild_a.png'),
    selectedIconPath: require('@/assets/tabBar/gameGuild_b.png'),
    text: '社區',
    icon: 'icon-tuanduichengyuan'
  },
  // {
  //   url: '/browers',
  //   iconPath: require('@/assets/tabBar/invitation_a.png'),
  //   selectedIconPath: require('@/assets/tabBar/invitaion_b.png'),
  //   text: '瀏覽器',
  //   icon: 'icon-liulanqi2'
  // },
  {
    url: '/ecologicalRewardMusd',
    iconPath: require('@/assets/tabBar/pledge_a.png'),
    selectedIconPath: require('@/assets/tabBar/pledge_b.png'),
    text: '質押',
    icon: 'icon-zhiyajiezhiya'
  },
  // {
  //   url: '/ecology',
  //   text: '生態',
  //   icon: 'icon-shengtaiquan'
  // },
  {
    url: '/twins',
    iconPath: require('@/assets/tabBar/invitation_a.png'),
    selectedIconPath: require('@/assets/tabBar/invitaion_b.png'),
    text: '分享',
    icon: 'icon-yaoqing'
  }],
  miniList: [
    {
      url: `${config.pancakeUrl + config.pancakeAdd}`,
      text: '獲取LP'
    },
    {
      url: '/staking?index=1',
      text: '質押LP'
    },
    {
      url: '/staking?index=2',
      text: '取回LP'
    },
    {
      url: `${config.pancakeUrl + config.pancakePool}`,
      text: '取回代幣'
    }
  ],
  daoList: [
    {
      url: '/m-dao/home',
      text: '首頁',
      icon: 'icon-a-lujing185'
    },
    {
      url: '/m-dao/campaign',
      text: '理事竟选',
      icon: 'icon-a-zu208'
    },
    {
      url: '/m-dao/referendum',
      text: '提案公投',
      icon: 'icon-a-zu207'
    },
    {
      url: '/m-dao/proposal',
      text: '社區提案',
      icon: 'icon-a-lujing193'
    },
    {
      url: '/m-dao/my',
      text: '我的',
      icon: 'icon-a-lujing194'
    }
  ]
}
