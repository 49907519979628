export const EN = {
  stake: 'Stake',
  balanceLp: 'Balance',
  times: 'Weight ',
  stakeTips: 'Please enter the LP quantity to be pledged',
  redeem: 'Redeem',
  reward: 'Reward',
  redemption: 'Redemption',
  ecologicalReward: 'Ecological LP Reward',
  commonStakeText: 'LP Stake',
  singleStakeText: 'Single Pledge',
  amountOfStake: 'Enter LPs to be pledged',
  enterAmount: 'Enter the amount',
  max: 'MAX',
  balance: 'Balance',
  chooseTime: 'Choose stake time',
  lp: 'Current 1 LP value',
  hashrate: 'Expected Earnings',
  amount: 'Amount(LP)',
  block: 'Stake Block',
  unlock: 'Date',
  event: 'Events',
  unstaked: 'Unstaked',
  unredeem: 'Events',
  alreadyRedeem: 'Redeemed',
  day: 'day',
  hour: 'hour',
  second: 'second',
  minute: 'minute',
  TVL: 'TVL',
  state: 'state',
  StakeTime: 'Stake Time',
  Expired: 'Expired',
  Viewrecordsonthechain: 'View records on the chain',
  getLp: 'Get LP from PancakeSwap',
  notice:
    'Due to transaction delays in transactions on the chain:\n' +
    '*It takes about 5 minutes to complete the entire process.\n' +
    '*The earnings will be obtained about 10 minutes after the pledge is completed.',
  remove: 'Remove LP from PancakeSwap',
  success: 'Staking Success',
  addLiquidty: {
    getLp: 'Get LP from PancakeSwap',
    enterNotice: 'Enter the amount of USDT',
    notice: 'Needs to be ≥ ',
    needMeb: 'At the same time ',
    balance: 'Balance',
    max: 'Max',
    get: 'Get LP',
    authUSDT: 'Supply USDT',
    authMEB: 'Supply MEB',
    pancake: 'Go PancakeSwap'
  },
  removeLiquidty: {
    removeTitle: 'Remove LP from PancakeSwap',
    enterNotice: 'Enter the number of LPs', // Enter the number of LPs to be retrieved
    notice: 'Please enter the quantity',
    balance: 'Balance',
    max: 'Max',
    nowValue: 'The value of the currently entered LP',
    authLP: 'Supply LP',
    retrieve: 'Remove',
    pancake: 'Go PancakeSwap'
  },
  activeTip: '12x within 30 days after the new pledge, 6x after 30 days',
  singleStake: {
    stake: 'Stake',
    choseStake: 'Select lock strategy',
    currentWeight: 'current exchange rate:',
    stakeWeight: 'Lock-up exchange ratio:',
    currentApy: 'Current Apy:',
    stakeApy: 'Lockdown Apy:',
    getMDAO: 'Get xMEB:',
    income: 'MEB Income',
    extract: 'Extract',
    mebTotalStake: 'Total number of MEB pledges',
    unlock: 'Unlock',
    MDAO: 'DAO Credentials (xMEB)',
    currentLock: 'Current lock',
    currentMeb: 'Current MEB',
    extractMeb: 'Extractable MEB',
    regularLock: 'Regular lock-up',
    lockTime: 'Lock-up',
    unlockTime: 'Unlock',
    lockQuantity: 'Quantity',
    lockDay: 'Lockup date',
    day: 'days',
    h: 'hours',
    min: 'mins',
    lack: 'Lack of balance',
    comfirm: 'Comfirm',
    cancle: 'Cancel',
    supplyX: 'Supply xMEB',
    extracted: 'Extracted',
    none: 'No data',
    current: 'Current',
    unlockNotice: 'Are you sure to unlock the pledge in advance?',
    unlockDes: 'If you unlock the pledge in advance, you will deduct',
    stakeNotice: 'You can only stake once within 12 hours',
    add: 'ADD'
  }
}
export const ZH = {
  stake: '質押',
  balanceLp: '余額',
  times: '權重',
  stakeTips: '請輸入要質押的LP數量',
  redeem: '取回',
  reward: '獎勵',
  redemption: '贖回',
  ecologicalReward: '生態LP獎勵',
  commonStakeText: 'LP 質押',
  singleStakeText: '單幣質押',
  amountOfStake: '輸入要質押的LP數量',
  enterAmount: '請輸入質押數量',
  max: '最大',
  balance: '剩',
  chooseTime: '選擇質押時長',
  lp: '當前1LP',
  hashrate: '預計獲得收益',
  amount: '數量(LP)',
  block: '質押高度',
  unlock: '日期',
  event: '操作',
  unstaked: '取回',
  unredeem: '未到期',
  alreadyRedeem: '已取回',
  day: '天',
  hour: '小時',
  second: '秒',
  minute: '分鐘',
  TVL: '總流動性質押',
  state: '狀態',
  StakeTime: '質押時間',
  Expired: '已到期',
  Viewrecordsonthechain: '查看鏈上記錄',
  getLp: '從PancakeSwap獲取LP',
  notice:
    '因鏈上交易存在交易延遲：\n' +
    '*整個流程操作完成需要10分鐘左右。',
  remove: '從PancakeSwap取回LP',
  success: '質押成功',
  addLiquidty: {
    getLp: '從PancakeSwap獲取LP',
    enterNotice: '輸入質押的 USDT數量',
    notice: '本次輸入需 ≥ ',
    needMeb: '同時需要',
    balance: '剩',
    max: '最大',
    get: '獲取LP',
    authUSDT: '供應USDT',
    authMEB: '供應MEB',
    pancake: '跳轉到PancakeSwap'
  },
  removeLiquidty: {
    removeTitle: '從PancakeSwap取回LP',
    enterNotice: '輸入要取回的LP數量',
    notice: '請輸入數量',
    balance: '剩',
    max: '最大',
    nowValue: '當前輸入的LP的價值',
    authLP: '執行使用LP',
    remove: '取回',
    pancake: '跳轉到PancakeSwap'
  },
  activeTip: '新增質押之後30天內12x，30天之後為6x',
  singleStake: {
    stake: '質押',
    choseStake: '選擇鎖倉策略',
    currentWeight: '活期兌換比例：',
    stakeWeight: '鎖倉兌換比例：',
    currentApy: '活期 APY:',
    stakeApy: '鎖倉 APY:',
    getMDAO: '獲得 xMEB:',
    income: 'MEB收入',
    extract: '提取',
    mebTotalStake: 'MEB質押總數',
    unlock: '解鎖',
    MDAO: 'DAO 憑證(xMEB)',
    currentLock: '活期鎖倉',
    currentMeb: '活期MEB',
    extractMeb: '可提取的MEB',
    regularLock: '定期鎖倉',
    lockTime: '鎖倉時間',
    unlockTime: '解鎖時間',
    lockQuantity: '鎖倉數量',
    lockDay: '鎖倉天數',
    day: '天',
    h: '小时',
    min: '分钟',
    lack: '剩不足',
    comfirm: '確定',
    cancle: '取消',
    supplyX: '供應xMEB',
    extracted: '已提取',
    none: '暫無數據',
    current: '活期',
    unlockNotice: '確定提前解鎖該質押嗎?',
    unlockDes: '提前解鎖該質押將扣除您',
    stakeNotice: '十二小時內只能質押一次',
    add: '添加'
  }
}
