export const EN = {
  level: {
    bronze: 'Bronze',
    silver: 'Silver',
    gold: 'Gold'
  },
  header: {
    hashrate: 'Total Hashrate',
    commission: 'Total Commission',
    airdrop: 'Total Airdrop',
    price: 'MEB Price',
    users: 'DAO member',
    newUsers: 'new member'
  },
  airdrop: {
    time: 'Last airdrop time: ',
    count: 'Last airdrop amount: '
  },
  node: {
    total: 'Total Node reward',
    last: 'Last reward'
  },
  contract: {
    meb: 'MEB Contract',
    stake: 'Stake Contract',
    airdrop: 'Airdrop Address',
    coin: 'Coin Address'
  },
  list: {
    notice: 'Enter address search',
    rank: 'Rank',
    Address: 'Address',
    Level: 'Level',
    hashrate: 'Hashrate',
    commsission: 'Commission',
    Lp: 'LP Amount',
    airdrop: 'Total Airdrop',
    staked: 'Staked record',
    hash: 'Txh Hash',
    height: 'Stake Height',
    unlock: 'Unlock Height',
    countdown: 'Time',
    record: 'Stake record',
    day: 'd ',
    check: 'check',
    ok: 'ok',
    h: 'h ',
    m: 'm ',
    s: 's ',
    ago: 'ago'
  }
}
export const ZH = {
  level: {
    bronze: '青銅',
    silver: '白銀',
    gold: '黃金'
  },
  header: {
    hashrate: '累計原力值',
    commission: '累計助力值',
    airdrop: '累計空投',
    price: 'MEB價格',
    users: 'DAO成員',
    newUsers: '新成員'
  },
  airdrop: {
    time: '上一次空投時間: ',
    count: '上一次空投金額:'
  },
  node: {
    total: '累計節點空投',
    last: '上一次的空投'
  },
  contract: {
    meb: 'MEB合約',
    stake: '質押合約',
    airdrop: '空投地址',
    coin: '鑄幣地址'
  },
  list: {
    notice: '輸入地址搜索',
    rank: '排名',
    Address: '地址',
    Level: '等級',
    hashrate: '原力值',
    commsission: '助力值',
    Lp: 'LP數量',
    airdrop: '空投累計量',
    staked: '質押記錄',
    hash: '交易Hash',
    height: '質押高度',
    unlock: '解鎖高度',
    countdown: '時間',
    day: '天',
    record: '質押記錄',
    check: '查看',
    ok: '完成',
    h: '時',
    m: '分',
    s: '秒',
    ago: '前'
  }
}
