import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../main.js'
Vue.use(Vuex)

let chain, authorized, userInfo, mbLang, tabBarList, myAcount, stakeTitle, isLoading, loadingIconType, needCreateAccount
try {
  chain = localStorage.getItem('chain')
} catch {
  chain = null
}
try {
  authorized = localStorage.getItem('authorized')
} catch {
  authorized = null
}
try {
  userInfo = JSON.parse(localStorage.getItem('userInfo'))
} catch {
  userInfo = null
}
try {
  mbLang = localStorage.getItem('mbLang')
} catch {
  localStorage.setItem('mbLang', 'EN')
  mbLang = 'EN'
}
try {
  tabBarList = localStorage.getItem('tabBarList')
} catch {
  tabBarList = i18n.t('tabBar.list')
}
try {
  myAcount = localStorage.getItem('mbMyAcount')
} catch {
  myAcount = null
}

try {
  isLoading = false
} catch {
  isLoading = false
}

try {
  needCreateAccount = false
} catch {
  needCreateAccount = false
}

export default {
  chain,
  authorized,
  userInfo,
  webviewUrl: null,
  scene: 0,
  mbLang,
  tabBarList,
  myAcount, // 钱包账户地址
  stakeTitle, // 质押标题
  isLoading, // 全局loding
  loadingIconType, // loading的icon
  needCreateAccount // 未注册的账户标识 true 无账户需要创建
}
