import store from '@/store/index'
import router from '../router'
// 用户设置 token
const userSetQueryToken = (option) => {
  console.log('1111')
  console.log(option, '11111')
  const authorized = option.authorized
  if (!authorized) {
    return
  }
  store.dispatch('changeToken', authorized)
}

const handleToPage = (url) => {
  if (url) {
    // 跳转
    router.push(url)
  } else {
    router.replace('/')
  }
}

// 货币六位小数
const formatSixBalance = (value) => {
  if (!value) return 0
  value = Number(value.toString().match(/^\d+(?:\.\d{0,6})?/))
  return value
}

// 货币四位小数
const formatBalance = (value) => {
  if (!value) return 0
  value = Number(value.toString().match(/^\d+(?:\.\d{0,4})?/))
  return value
}

// 货币两位小数
const formatTwoBalance = (value) => {
  if (!value) return 0
  value = Number(value.toString().match(/^\d+(?:\.\d{0,2})?/))
  return value
}

// 货币3位小数
const formatThreeBalance = (value) => {
  if (!value) return 0
  value = Number(value.toString().match(/^\d+(?:\.\d{0,3})?/))
  return value
}
// 钱包地址格式化
const formatAddress = (value) => {
  if (!value) return 0
  const startAddress = value.slice(0, 5)
  const endAddress = value.slice(-5)
  const address = startAddress + '...' + endAddress
  return address
}

// 计算 usdt 和 meb 的值
// USDT数量=LP数量*当前LP价值/2
// MEB数量=LP数量*当前LP价值/2/MEB当前价格

const getUsdtMebAmount = (lpcount, lpPrice, mebPrice) => {
  const usdt = formatTwoBalance((lpcount * lpPrice) / 2)
  const meb = formatTwoBalance(usdt / mebPrice)
  return {
    usdt,
    meb
  }
}

// 数字转为三位一隔
const formatNumber = (nStr) => {
  var x, x1, x2
  nStr += ''
  x = nStr.split('.')
  x1 = x[0]
  x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}
// 格式化原力值助力值
const formatValue = (value) => {
  if (value < Math.pow(10, 3)) {
    return `${formatThreeBalance(value)} H`
  } else if (value < Math.pow(10, 6)) {
    return `${formatThreeBalance(value / Math.pow(10, 3))} KH`
  } else if (value < Math.pow(10, 9)) {
    return `${formatThreeBalance(value / Math.pow(10, 6))} MH`
  } else if (value < Math.pow(10, 12)) {
    return `${formatThreeBalance(value / Math.pow(10, 9))} TH`
  } else if (value < Math.pow(10, 15)) {
    return `${formatThreeBalance(value / Math.pow(10, 12))} PH`
  } else {
    return `${formatThreeBalance(value / Math.pow(10, 15))} EH`
  }
}

// 数字增长动画
const animateValue = (obj, start, end, duration) => {
  let startTimestamp = null
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp
    const progress = Math.min((timestamp - startTimestamp) / duration, 1)
    obj.innerHTML = Math.floor(progress * (end - start) + start)
    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }
  window.requestAnimationFrame(step)
}

// 輸入1，輸出001（n 為3）
const PrefixInteger = (num, n = 3) => {
  return (Array(n).join(0) + num).slice(-n)
}
const gbUtils = {
  userSetQueryToken,
  handleToPage,
  formatBalance,
  formatTwoBalance,
  formatAddress,
  getUsdtMebAmount,
  formatNumber,
  formatValue,
  animateValue,
  formatThreeBalance,
  PrefixInteger,
  formatSixBalance
}

export default gbUtils
