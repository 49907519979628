export const EN = {
  title: 'M-dao Global launcher The first EGG DAO in the world',
  subTitle: '-Co-sponsors and creation community hot recruitment-',
  des: 'Fair start | transparent coordination | safety management',
  eggDes:
    "Explorer Guild Game DAO (EGG DAO for short) is the world's first guild-based financial DAO jointly initiated by the crypto community and meta-universe Guild. It is committed to providing incubation soil, capital enabling and flow support for WEB3.0, METAVERSE and GAMEFI track quality targets. The goal of EGG DAO is to capitalize the DAO community organization through a fair, open and transparent launch mechanism, establish a GUILDFI (guild finance) interconnected ecosystem of game, community and NFT assets, and strive to maximize the interests of the community and players.",
  mDaoDes:
    'M-dao is an important step in the decentralization of MEB. Come and make suggestions for the future ecological development of MEB',
  more: 'open',
  less: 'close'
}

export const ZH = {
  title: 'M-DAO全球發射器全球首發EGG DAO',
  subTitle: '-聯合發起人及創世社區火熱招募中-',
  des: '公平啟動 | 透明合作 | 安全治理',
  eggDes:
    'Explorer Guild Game DAO，簡稱EGG DAO，是全球首個由加密社區和元宇宙公會共同發起的公會化金融DAO，致力於為WEB3. 0、METAVERSE及GAMEFI賽道優質標的提供孵化土壤、資本賦能及流量支持。 EGG DAO的目標是通過公平、公開、透明的發射機制完成DAO社區組織的資本化，建立遊戲、社區和NFT資產的GUILDFI（公會化金融）互聯生態系統，努力實現社區及玩家的利益最大化。',
  mDaoDes: 'M-DAO是MEB去中心化的重要一步，快來為MEB未來生態發展建言獻策',
  more: '展開',
  less: '收起'
}
