import axios from 'axios'
import store from '@/store'
import config from '@/config/app.config'
import { Toast } from 'vant'
// import router from '../router'
// axios.defaults.withCredentials = true
axios.defaults.crossDomain = true
axios.defaults.timeout = 8000

// const getErrorMessage = (status) => {
//   switch (status) {
//     case 400: return '请求错误'
//     case 401: return '未授权，请重新登录'
//     case 403: return '拒绝访问'
//     case 404: return '请求出错'
//     case 408: return '请求超时'
//     case 500: return '服务器错误'
//     case 501: return '功能正在路上'
//     case 502: return '网络错误'
//     case 503: return '服务不可用'
//     case 504: return '网络超时'
//     case 505: return 'HTTP版本不受支持'
//     case 510: return '当前钱包不存在'
//     default: return `连接出错(${status})!`
//   }
// }

const request = (method, url, params, needToken = true, noLoading, loadingIconType, headerContentType = 'application/json', showErrorMessage = true) => {
  const headers = {
    'Content-Type': headerContentType
  }
  headers.lang = store.state.mbLang || 'ZH'
  if (needToken) { headers.authorized = store.state.authorized || '' } else {
    headers.authorized = ''
  }
  // if (needToken) { headers.authorized = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNDY0NTAwMzA2NjgzODIyMDgxIiwiY3JlYXRlVGltZSI6IjIwMjEtMTItMDIgMTU6NDk6MDkiLCJpc3MiOiJjdXN0b21lciIsImV4cCI6MTY0MTAyMzM0OX0.OFyQcScDAevA1w-Mx62fgjwY_IKwBx1Dgda9jUC7rgOWTyCfiscOGs_W0yU282zxl6YkJ3RkNiq2AK-kSVEqfA' }
  // if (needToken) { headers.authorized = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNDU0MDE0NjE5NDkwNzIxNzk0IiwiY3JlYXRlVGltZSI6IjIwMjEtMTEtMDkgMTA6MjM6MDkiLCJpc3MiOiJjdXN0b21lciIsImV4cCI6MTYzOTAxNjU4OX0.gGF8ZaKJGLqi22aeidfq3l-kCD3oJyBIiL89Rrxk9Zct5pO9B-6CPMeFkMWmbBlgJEuxoN8XmDHBz_hdlo8Law' }
  if (noLoading) {
  } else {
    // 显示全局加载loading
    store.state.isLoading = true
  }
  if (loadingIconType) {
    store.state.loadingIconType = loadingIconType
  } else {
    store.state.loadingIconType = ''
  }
  return new Promise((resolve, reject) => {
    axios({
      method,
      headers,
      baseURL: config.server,
      url,
      timeout: 15000,
      params: method === 'GET' || method === 'DELETE' ? params : null, // 是即将与请求一起发送的 URL 参数
      data: method === 'POST' || method === 'PUT' ? params : null // 是作为请求主体被发送的数据
    }).then(res => {
      // 全局加载样式隐藏
      setTimeout(() => {
        store.state.isLoading = false
      }, 500)
      Toast.clear()
      if (res.data.code === 422) {
        localStorage.removeItem('authorized')
        store.commit('SET_TOKEN', '')
        localStorage.removeItem('mbMyAcount')
        store.commit('SET_MY_ACCOUNT', '')
        // router.replace('/').catch(err => { console.log(err) })
        resolve({ success: false, records: [], result: {}, total: 0, message: '登录已失效，请重新登录' })
      } else if (res.data.code === 200) {
        res.data.success = true
        resolve(res.data)
      } else if (res.data.code === 510) {
        // 标识没有账户
        store.commit('SET_NEED_CREATE_ACCOUNT', true)
        res.data.success = false
        resolve(res.data)
      } else {
        res.data.success = false
        Toast.fail(res.data.message, 5)
        resolve(res.data)
      }
    }).catch(error => {
      Toast.clear()
      let messageText = ''
      if (error.response && error.response.data && error.response.data.message) {
        messageText = error.response.data.message
      } else {
        // messageText = error.response ? getErrorMessage(error.response.status) : 'Network exception'
        messageText = 'Network exception'
      }
      Toast.fail(messageText, 5)
      // reject(error)
      resolve({ success: false, records: [], result: {}, total: 0, message: 'Network exception' })
    })
  })
}

export default request
