import Web3 from 'web3'
import config from '@/config/app.config.js'
import store from '@/store'
import tokenAbi from '../static/abi/token.json'
const contractsInfo = config.contractsInfo

const provider = 'https://bsc-dataseed1.ninicoin.io'
// const providerTest = 'https://data-seed-prebsc-1-s1.binance.org:8545'

var web3 = new Web3()
try {
  web3 = new Web3(window.ethereum)
} catch {
  web3 = new Web3(new Web3.providers.HttpProvider(provider))
  // web3 = new Web3(new Web3.providers.HttpProvider(providerTest))
}
const ethereum = window.ethereum
const currentAccount = store.state.myAcount // 当前钱包账户
// pancake合约对象
const contractRouter = new web3.eth.Contract(
  contractsInfo.routerContract.abi,
  contractsInfo.routerContract.address
)
// 统一错误返回
const failResult = (message) => ({ success: false, message })
// 统一成功返回
const successResult = (result) => ({ success: true, result })

/**
 * 获取精度
 * decimals
 */
const decimalsList = {
  0: 'noether',
  1: 'wei',
  3: 'kwei',
  6: 'mwei',
  9: 'gwei',
  12: 'szabo',
  15: 'milliether',
  18: 'ether',
  21: 'kether',
  24: 'mether',
  27: 'gether',
  30: 'tether'

}

/**
 * 获取当前rpc Id
 * @returns chainId
 */
const getChainId = async () => {
  try {
    const chainId = await web3.eth.net.getId()
    return chainId
  } catch (error) {
    return 0
  }
}

/**
 * 获取当前区块高度
 * @returns block
 */
const getBlock = async () => {
  try {
    const block = await web3.eth.getBlockNumber()
    return block
  } catch (error) {
    return 0
  }
}
/**
 * 创建钱包（账户）
 * @payload 创建钱包的随机自定义字符串（可不填），用于增加混乱度的随机字符串，至少32字符长。如果未设定将使用randomhex生成一个随机字符串
 * @return 返回钱包内容
 */
const createAccount = (payload) => {
  const web3 = new Web3(config.provider)
  if (payload && payload.length < 32) {
    return failResult('随机字符串长度小于32位')
  }
  try {
    const myAcount = web3.eth.accounts.create(payload)
    return successResult(myAcount)
  } catch {
    return failResult('创建账户失败')
  }
}

/**
 * 签名数据
 * @account 需要签名的账户
 * @data 需要签名的数据
 * @privatekey 私钥
 * @return 签名后的数据
 */
const signData = async (account, data) => {
  if (!data) return failResult('签名数据不可为空')
  try {
    const signedData = await web3.eth.personal.sign(data, account)
    return successResult(signedData)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 计算给定消息的哈希
 * @message 要进行哈希计算的消息
 * @return 哈希过的消息
 */
const hashMessage = async (message) => {
  try {
    const resp = await web3.eth.accounts.hashMessage(message)
    return successResult(resp)
  } catch (err) {
    console.error(err)
    return failResult(err)
  }
}

/**
 * 连接metamask，获取账号
 */
const connectWallet = async () => {
  // 判断用户是否安装MetaMask钱包插件
  if (!window.ethereum) {
    const message = {
      message: 'No Wallet!'
    }
    return failResult(message)
  }
  try {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts'
    })
    // ethereum.enable() // 旧的API
    store.commit('SET_MY_ACCOUNT', accounts[0]) // 暂存account
    const ethChainId = await window.ethereum.request({ method: 'eth_chainId' })
    const ethChainNumberId = web3.utils.hexToNumber(ethChainId)
    if (ethChainNumberId !== config.ethChainNumberId) {
      window.ethereum
        .request({
          method: 'wallet_addEthereumChain',
          params: [config.chainConfig]
        })
        .then(() => {
          location.reload()
        })
    }
    return successResult(accounts[0])
  } catch (reason) {
    // reason.code => 4001 用户拒绝
    const message = reason.message || 'There was a problem signing you in'
    // Toast(reason.message)
    return failResult(message)
  }
}

/**
 * 获取当前token数量
 *@myAcount 当前账户地址
 */
const getBalance = async (myAcount = currentAccount) => {
  myAcount = store.state.myAcount
  try {
    var balance = await web3.eth.getBalance(myAcount)
    // balance = balance.toNumber() / Math.pow(10, 18)
    balance = web3.utils.fromWei(balance, 'ether')
    return successResult(balance)
  } catch (error) {
    return failResult(error)
  }
}
/**
 * 转账
 * @fromAddress 当前地址
 * @toAddress 目的地址
 * @value 数额
 */
const transfer = async (toAddress, value) => {
  value = web3.utils.toWei(value, 'ether')
  const currentAccount = store.state.myAcount
  try {
    const resp = await web3.eth.sendTransaction({
      from: currentAccount,
      to: toAddress,
      value: value
    })
    console.log(resp)
    return successResult(resp)
  } catch (err) {
    console.log(err)
    return failResult(err)
  }
}

/**
 * 查询某个账号的代币剩
 * @param {contractAddress} 币种合约
 * @param {currentAccount} 账户地址
 * @returns 代币数量
 */

const balanceOf = async (contractObj) => {
  const currentAccount = store.state.myAcount
  const myContract = await new web3.eth.Contract(
    contractObj.abi,
    contractObj.address
  )
  try {
    // const resp = await myContract.methods.name().call({ from: contractObj.address })
    var balance = await myContract.methods
      .balanceOf(currentAccount)
      .call({ from: currentAccount })
    // console.log(balance)
    balance = web3.utils.fromWei(balance, 'ether')
    return successResult(balance)
  } catch (error) {
    console.log(error)
    return failResult(error)
  }
}

/**
 * 获取当前token发行量
 * @param {contractAddress} 币种合约
 * @param {currentAccount} 账户地址
 * @returns 代币供应量
 */
const getTotalSupply = async (contractObj) => {
  const currentAccount = store.state.myAcount
  const myContract = await new web3.eth.Contract(
    contractObj.abi,
    contractObj.address
  )
  try {
    var balance = await myContract.methods
      .totalSupply(currentAccount)
      .call({ from: currentAccount })
    console.log(balance)
    balance = web3.utils.fromWei(balance, 'ether')
    return successResult(balance)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * approveContract 授权dex使用币
 * @contractObj 合约对象
 * @param inputValue 输入的数量
 */

const approveContract = async (contractObj, inputValue) => {
  const chainId = await web3.eth.net.getId()
  console.log('当前chaidId:', chainId)
  const stringValue = web3.utils.toWei(inputValue.toString(), 'ether')
  // const stringValue = String(inputValue.toString() + '000000000000000000')
  const accounts = await web3.eth.getAccounts()
  try {
    const resp = await contractObj.methods
      .approve(
        contractsInfo.routerContract.address.toString().toLowerCase(),
        stringValue
      )
      .send({
        from: accounts[0]
      })
    return successResult(resp)
  } catch (error) {
    console.log(error)
    return failResult(error)
  }
}

/**
 * quote 查询 获得交易对的兑换价格
 * @uint256 amountA
 * @uint256 reserveA
 * @uint256 reserveB
 * @return uint256 reserveB
 */
const quote = async (amountA, reserveA, reserveB) => {
  amountA = web3.utils.toWei(amountA.toString(), 'ether')
  reserveA = web3.utils.toWei(reserveA.toString(), 'ether')
  reserveB = web3.utils.toWei(reserveB.toString(), 'ether')

  try {
    var resp = await contractRouter.methods
      .quote(amountA, reserveA, reserveB)
      .call({ from: currentAccount })
    resp = web3.utils.fromWei(resp, 'ether')
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

const fromWei = (amount, num = 18) => {
  return web3.utils.fromWei(amount, decimalsList[num])
}

/**
 * 获取当前区块高度
 * @returns block
 */
const getBlockInfo = async (block) => {
  try {
    const blockResp = await web3.eth.getBlock(block)
    return blockResp
  } catch (error) {
    return 0
  }
}

/**
 * 转账
 * @fromAddress 当前地址
 * @toAddress 目的地址
 * @value 数额
 */
const transferMeb = async (toAddress, amount) => {
  const contractMeb = new web3.eth.Contract(
    contractsInfo.tokenGEPContract.abi,
    contractsInfo.tokenGEPContract.address
  )
  amount = web3.utils.toWei(amount.toString(), 'ether')
  const currentAccount = store.state.myAcount
  try {
    const resp = await contractMeb.methods.transfer(toAddress, amount).send({
      from: currentAccount
    })
    return successResult(resp)
  } catch (err) {
    console.log(err)
    return failResult(err)
  }
}

const tokenBalance = async (tokenAddress, account) => {
  const currentAccount = store.state.myAcount
  const myContract = await new web3.eth.Contract(tokenAbi, tokenAddress)
  try {
    // const resp = await myContract.methods.name().call({ from: contractObj.address })
    var balance = await myContract.methods
      .balanceOf(account || currentAccount)
      .call({})
    balance = web3.utils.fromWei(balance, 'ether')
    return successResult(balance)
  } catch (error) {
    return failResult(error)
  }
}

// 添加代币
const addToken = async (address, symbol) => {
  try {
    const resp = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: address,
          symbol: symbol,
          decimals: 18
        }
      }
    })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 *
 * @param {tokenAddress} 合约地址
 * @returns
 */
const getSymbol = async (tokenAddress) => {
  const tokenContract = await new web3.eth.Contract(tokenAbi, tokenAddress)
  try {
    const resp = await tokenContract.methods.symbol().call({})
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

const decimals = async (tokenAddress) => {
  const tokenContract = await new web3.eth.Contract(tokenAbi, tokenAddress)
  try {
    const resp = await tokenContract.methods.decimals().call({})
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

const mebloxWeb3 = {
  addToken,
  ethereum,
  getBlock,
  getChainId,
  contractRouter, // 路由
  createAccount,
  signData,
  hashMessage,
  connectWallet,
  getBalance,
  transfer,
  balanceOf,
  approveContract,
  quote,
  fromWei,
  getTotalSupply,
  getBlockInfo,
  transferMeb,
  tokenBalance,
  getSymbol,
  decimals
}

export default mebloxWeb3
