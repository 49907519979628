import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// haveBottomTabBar: true, // 是否有底部tab,
// haveNavigationBar： true 顶部导航
// isBack 是否返回上一级页面
const routes = [
  {
    path: '/',
    redirect: '/staking'
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      haveNavigationBar: true,
      haveBottomTabBar: true, // 是否有底部tab,
      selected: 0
    },
    component: () => import('../views/home/home.vue')
  },
  // 钱包 start
  {
    path: '/wallet',
    name: '钱包',
    meta: {
      haveBottomTabBar: true, // 是否有底部tab
      selected: 2
    },
    component: () => import('../views/wallet/wallet.vue')
  },
  {
    path: '/getLP',
    name: 'GetLP',
    meta: {
      haveNavigationBar: true,
      isBack: true
    },
    component: () => import('../views/wallet/getLP/getLP.vue')
  },
  // 质押
  {
    path: '/staking',
    name: 'Staking',
    meta: {
      haveNavigationBar: true,
      isBack: false,
      haveBottomTabBar: true, // 是否有底部tab
      selected: 2
    },
    component: () => import('../views/wallet/staking/staking.vue')
  },
  // 赎回
  {
    path: '/redeem',
    name: 'Redeem',
    meta: {
      isBack: true
    },
    component: () => import('../views/wallet/redeem/Redeem.vue')
  },
  // 钱包 end
  // lottery start
  {
    path: '/lottery',
    name: 'Lottery',
    meta: {},
    component: () => import('../views/lottery/Lottery.vue')
  },
  {
    path: '/lotteryHistroy',
    name: 'LotteryHistory',
    meta: {
      isBack: true
    },
    component: () =>
      import('../views/lottery/LotteryHistory/LotteryHistory.vue')
  },
  {
    path: '/lotteryRule',
    name: 'LotteryRule',
    meta: {
      isBack: true
    },
    component: () => import('../views/lottery/LotteryRule.vue')
  },
  {
    path: '/myLottery',
    name: 'MyLottery',
    meta: {
      isBack: true
    },
    component: () => import('../views/lottery/MyLottery/MyLottery.vue')
  },
  // lottery end
  // 双生 start
  {
    path: '/twins',
    name: '双生',
    meta: {
      haveBottomTabBar: true, // 是否有底部tab
      haveNavigationBar: true,
      selected: 3
    },
    component: () => import('../views/twins/twins.vue')
  },
  {
    path: '/MyFriends',
    name: 'MyFriends',
    meta: {
      haveNavigationBar: true,
      isBack: true
    },
    component: () => import('../views/twins/MyFriends.vue')
  },
  {
    path: '/Invitation',
    name: 'Invitation',
    meta: {
      haveNavigationBar: true,
      isBack: true
    },
    component: () => import('../views/twins/Invitation.vue')
  },
  // 双生 end
  // 公告 start
  {
    path: '/notice',
    name: 'notice',
    meta: {
      title: 'Announce',
      haveNavigationBar: true,
      isBack: true,
      // haveBottomTabBar: true, // 是否有底部tab
      selected: 1
    },
    component: () => import('../views/notice/noticeDetail.vue')
    // component: () => import('../views/notice/notice.vue')
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    meta: {
      title: 'Announce',
      haveNavigationBar: true,
      isBack: true
    },
    component: () => import('../views/notice/noticeDetail.vue')
  },
  // 公告 end
  // 视频 end
  {
    path: '/videoList',
    name: 'videoList',
    meta: {
      haveNavigationBar: true,
      isBack: true
    },
    component: () => import('../views/video/videoList.vue')
  },
  {
    path: '/video',
    name: 'video',
    meta: {
      haveNavigationBar: true,
      isBack: true
    },
    component: () => import('../views/video/video.vue')
  },
  // 视频 end
  // 仓库 start
  {
    path: '/warehouse',
    name: 'warehouse',
    meta: {
      haveNavigationBar: true,
      isBack: true
    },
    component: () => import('../views/warehouse/warehouse.vue')
  },
  // 仓库 end
  // 浏览器 start
  {
    path: '/browers',
    name: 'browers',
    meta: {
      title: 'Browers',
      haveNavigationBar: true,
      // isBack: true,
      haveBottomTabBar: true // 是否有底部tab
      // selected: 2
    },
    component: () => import('../views/browers/Browers.vue')
  },
  // 浏览器 end
  // 骑士 start
  {
    path: '/knight',
    name: 'knight',
    meta: {
      title: 'Knight',
      isBack: true
    },
    component: () => import('../views/knightNft/knightNft.vue')
  },
  // 骑士 end
  // 社区 start
  {
    path: '/community',
    name: 'Community',
    meta: {
      title: 'Community',
      haveBottomTabBar: true, // 是否有底部tab,
      selected: 1
    },
    component: () => import('../views/community/Community.vue')
  },
  {
    path: '/communityDetail',
    name: 'CommunityDetail',
    meta: {
      title: 'CommunityDetail'
      // haveBottomTabBar: true, // 是否有底部tab,
      // selected: 1
    },
    component: () => import('../views/community/CommunityDetail.vue')
  },
  {
    path: '/createCommunity',
    name: 'CreateCommunity',
    meta: {
      title: 'CreateCommunity',
      isBack: true
    },
    component: () => import('../views/community/CreateCommunity.vue')
  },
  {
    path: '/setCommunity',
    name: 'SetCommunity',
    meta: {
      title: 'setCommunity',
      isBack: true
    },
    component: () => import('../views/community/SetCommunity.vue')
  },
  {
    path: '/setCommunityName',
    name: 'SetName',
    meta: {
      title: 'setCommunityName',
      isBack: true
    },
    component: () => import('../views/community/SetName.vue')
  },
  {
    path: '/setCommunityDec',
    name: 'SetDec',
    meta: {
      title: 'setCommunityDec',
      isBack: true
    },
    component: () => import('../views/community/SetDec.vue')
  },
  {
    path: '/setCommunityBtn',
    name: 'SetBtn',
    meta: {
      title: 'setCommunityBtn',
      isBack: true
    },
    component: () => import('../views/community/SetBtn.vue')
  },
  {
    path: '/communitys',
    naem: 'Communitys',
    meta: {
      title: 'Communitys',
      isBack: true,
      haveBottomTabBar: true, // 是否有底部tab,
      selected: 100
    },
    component: () => import('../views/community/Communitys.vue')
  },
  {
    path: '/subsidized',
    name: 'SubsidizedActivities',
    meta: {
      title: 'SubsidizedActivities',
      isBack: true
    },
    component: () => import('../views/community/SubsidizedActivities.vue')
  },
  // 社区 end
  // 单币质押 start
  {
    path: '/singleStaking',
    name: 'SingleStaking',
    meta: {
      title: 'SingleStaking',
      haveBottomTabBar: true, // 是否有底部tab,
      selected: 2
    },
    component: () => import('../views/wallet/singleStaking/SingleStaking.vue')
  },
  // 单币质押 end
  // meb-usd LP生态奖励 start
  {
    path: '/ecologicalReward',
    name: 'EcologicalReward',
    meta: {
      title: 'EcologicalReward',
      haveBottomTabBar: true, // 是否有底部tab,
      selected: 2
    },
    component: () => import('../views/wallet/ecologicalReward/EcologicalReward.vue')
  },
  // meb-usd LP生态奖励 end
  // meb-usd LP生态奖励赎回
  {
    path: '/redemption',
    name: 'Redemption',
    meta: {
      isBack: true
    },
    component: () => import('../views/wallet/redemption/Redemption.vue')
  },
  // meb-musd LP生态奖励 start
  {
    path: '/ecologicalRewardMusd',
    name: 'EcologicalRewardMusd',
    meta: {
      title: 'EcologicalRewardMusd',
      haveBottomTabBar: true, // 是否有底部tab,
      selected: 2
    },
    component: () => import('../views/wallet/ecologicalRewardMusd/EcologicalRewardMusd.vue')
  },
  // meb-musd LP生态奖励  end
  // meb-musd LP生态奖励 赎回
  {
    path: '/redemptionMebMusd',
    name: 'RedemptionMebMusd',
    meta: {
      isBack: true
    },
    component: () => import('../views/wallet/ecologicalRewardMusd/RedemptionMebMusd.vue')
  },
  // meb-musd LP生态奖励 赎回
  {
    path: '/reward',
    name: 'Reward',
    meta: {
      isBack: true
    },
    component: () => import('../views/wallet/reward/Reward.vue')
  },
  // m-dao start
  {
    path: '/m-dao',
    name: 'M-DAO',
    meta: {
      title: 'M-DAO',
      daoBottomTabBar: true
    },
    component: () => import('../views/m-dao/index.vue'),
    redirect: '/m-dao/home',
    children: [
      {
        path: 'home',
        name: 'M-DAO Home',
        meta: {
          title: 'home',
          daoBottomTabBar: true
        },
        component: () => import('../views/m-dao/home/Home.vue')
      },
      {
        path: 'my',
        name: 'M-DAO My',
        meta: {
          title: 'my',
          daoBottomTabBar: true
        },
        component: () => import('../views/m-dao/my/My.vue')
      },
      {
        path: 'campaign',
        name: 'M-DAO Campaign',
        meta: {
          title: 'campaign',
          daoBottomTabBar: true
        },
        component: () => import('../views/m-dao/campaign/Campaign.vue')
      },
      {
        path: 'referendum',
        name: 'M-DAO referendum',
        meta: {
          title: 'referendum',
          daoBottomTabBar: true
        },
        component: () => import('../views/m-dao/referendum/Referendum.vue')
      },
      {
        path: 'proposal',
        name: 'M-DAO Proposal',
        meta: {
          title: 'proposal',
          daoBottomTabBar: true
        },
        component: () => import('../views/m-dao/proposal/Proposal.vue')
      },
      {
        path: 'proposalDetail',
        name: 'M-DAO ProposalDetail',
        meta: {
          title: 'proposal',
          daoBottomTabBar: true
        },
        component: () => import('../views/m-dao/referendum/PropsalDetail.vue')
      },
      {
        path: 'applyProposal',
        name: 'M-DAO ApplyProposal',
        meta: {
          title: 'proposal',
          daoBottomTabBar: true
        },
        component: () => import('../views/m-dao/referendum/ApplyProposal.vue')
      }
    ]
  },
  // m-dao end
  // 生态 start
  {
    path: '/ecology',
    name: 'Ecology',
    meta: {
      title: 'Ecology',
      haveBottomTabBar: true, // 是否有底部tab,
      selected: 3
    },
    component: () => import('../views/ecology/Ecology.vue')
  }
  // 生态 end
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
export default router
