<template>
  <!-- @click-overlay="closePop" -->
  <VanPopup
    v-model="popShow"
    :close-on-click-overlay="false"
    position="bottom"
    style="
      width: 101%;
      max-width: 530px;
      left: 50%;
      transform: translateX(-50%);
    "
    round
    get-container="content"
  >
    <div class="login-page">
      <!-- 创建账号 -->
      <template v-if="creatFlag">
        <div class="title">{{ InviteCode }}</div>
        <van-field
          v-model="inviteCode"
          :placeholder="createWalletTitle.inviteCodeTitle"
          class="input-box"
          input-align="center"
        />
        <div class="botton-group">
          <div class="warehouse-btn" @click="closePop">
            {{ createWalletTitle.cancel }}
          </div>
          <div class="warehouse-btn" @click="handleRegister">{{ confirm }}</div>
        </div>
      </template>
      <!-- 授权登录 -->
      <template v-else>
        <div class="title">{{ ConnectWallet }}</div>
        <div class="botton-group">
          <div class="warehouse-btn" @click="closePop">
            {{ createWalletTitle.cancel }}
          </div>
          <div class="warehouse-btn" @click="handleToLogin">
            {{ Signature }}
          </div>
        </div>
      </template>
    </div>
  </VanPopup>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { queryWalletLogin, queryWalletRegister } from '../../services/login'
export default {
  data () {
    return {
      popShow: true,
      message: 'Welcome to Meblox!',
      messageHash: '', // 哈希后的数据
      signature: '', // 签名之后的字符串 长度:132,0x开头
      creatFlag: false, // 创建标识，填写邀请码
      inviteCode: '',
      defaultInviteCode: 'AVADOT'
    }
  },
  computed: {
    ...mapState(['myAcount']),
    ConnectWallet () {
      return this.$t('common.ConnectWallet')
    },
    InviteCode () {
      return this.$t('common.InviteCode')
    },
    Apporve () {
      return this.$t('common.Apporve')
    },
    confirm () {
      return this.$t('common.confirm')
    },
    createWalletTitle () {
      return this.$t('wallet.createWalletTitle')
    },
    Signature () {
      return this.$t('common.Signature')
    }
  },
  watch: {
    myAcount (val) {
      console.log('account change:', val)
      this.creatFlag = false
    }
  },
  methods: {
    ...mapActions(['changeToken', 'changeAccount']),
    // 点击关闭
    closePop () {
      this.$emit('hidePop') // 隐藏弹窗
    },
    // web3 登录
    async handleToLogin () {
      if (!this.myAcount) {
        const resp = await this.$web3.connectWallet()
        if (!resp.success) {
          return this.$toast(resp.message)
        }
      }
      // 签名
      const resp = await this.$web3.signData(this.myAcount, this.message)
      if (!resp.success) {
        localStorage.removeItem('mbMyAcount')
        this.changeAccount('')
        return this.$toast(resp.message.message)
      }
      this.signature = resp.result
      // 哈希
      const resp1 = await this.$web3.hashMessage(this.message)
      if (!resp1.success) {
        return this.$toast(resp1.message)
      }
      this.messageHash = resp1.result
      this.login()
    },
    // 登录
    async login () {
      if (!this.myAcount) return this.handleToLogin()
      const params = {
        address: this.myAcount,
        messageHash: this.messageHash,
        signature: this.signature
      }
      const resp = await queryWalletLogin(params)
      if (!resp.success) {
        if (resp.code === 510) {
          // 无账户
          this.creatFlag = true
          // this.$router.push({ name: 'createWallet', params: params })
        }
      } else {
        this.changeToken(resp.result.token)
        this.changeAccount(resp.result.address)
        this.$emit('loginSuccess') // 隐藏弹窗
      }
    },
    // 创建账号
    handleRegister () {
      if (!this.inviteCode) {
        // this.inviteCode = this.defaultInviteCode
        return this.$toast('please input inviteCode')
      }
      this.register()
    },
    // 注册
    async register () {
      const params = {
        name: 'meblox',
        inviteCode: this.inviteCode,
        messageHash: this.messageHash,
        signature: this.signature,
        address: this.myAcount
      }
      const resp = await queryWalletRegister(params)
      if (!resp.success) {
        this.$toast(resp.message)
      } else {
        this.changeToken(resp.result.token)
        this.changeAccount(resp.result.address)
        this.$emit('loginSuccess')
      }
    },
    // 创建web3钱包
    async handleCreat () {
      const resp = await this.$web3.createAccount()
      const acount = resp.result
      if (resp.success) {
        const signResp = await this.$web3.signData(acount, this.messageHash)
        console.log(signResp)
      }
    }
  },
  async mounted () {
    this.inviteCode = this.$route.query.mbCode
    // console.log(this.$web3.myContract)
    // this.$web3.addLiquidity()
    // this.$web3.transfer(this.myAcount, '1.1')
  }
}
</script>
<style lang="scss" scoped>
.login-page {
  text-align: center;
  padding: 35px 50px;
  box-sizing: border-box;
  .title {
    font-size: 18px;
    font-weight: normal;
    margin: auto;
    margin-bottom: 15px;
  }
  .botton-group {
    display: flex;
    justify-content: space-between;
  }
  .warehouse-btn {
    height: 50px;
    background: #24c294;
    border-radius: 10px;
    font-size: 17px;
    font-weight: bold;
    color: #2a2b33;
    line-height: 50px;
    text-align: center;
    margin-top: 20px;
    width: 48%;
    cursor: pointer;
  }
  .warehouse-btn:first-child {
    color: #c1c1c1;
    background: #f7f8fa;
  }
}
</style>
