export const EN = {
  Individual: 'Individual total reward weight',
  Lock: 'Lock up time',
  Unlock: 'Unlock time',
  pledge: 'Pledge days',
  quantity: 'Pledge quantity',
  weight: 'Pledge weight',
  Reward: 'Reward weight',
  extract: 'extract',
  claim: 'Claim'
}
export const ZH = {
  Individual: '個人總獎勵權重',
  Lock: '鎖倉時間',
  Unlock: '解鎖時間',
  pledge: '質押天數',
  quantity: '質押數量',
  weight: '質押權重',
  Reward: '獎勵權重',
  extract: '提取',
  claim: '領取'
}
