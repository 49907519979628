export const EN = {
  main: {
    hour: 'h',
    min: 'm',
    rewardNotice1: '',
    rewardNotice2: ' until the draw',
    BuyTickets: 'Buy Tickets',
    DrawTime: 'Draw time',
    haveTickets:
      '<span style="font-weight: Bold;"> {number} </span> tickets this round',
    viewTickets: 'View tickets',
    winPrize: 'Current winable prizes',
    history: 'Your History',
    gameIntroduction: 'Game Introduction',
    my: 'My',
    comingSoon: 'Coming Soon'
  },
  history: {
    Round: 'Round',
    Drawn: 'Drawn',
    Latest: 'Latest',
    WinningNumber: 'Winning Number',
    Prizepot: 'Prize pot',
    totalPlayer: 'Total players this round:',
    rules: 'Match the winning number in the same order to share prizes.',
    MatchFirst: 'Match first <span style="font-weight: Bold;">{number}</span>',
    DaoFund: 'DAO Ecological Fund',
    checkResult: 'Query lottery details'
  },
  // rules
  rules: {
    WinningCriteria: 'Winning criteria',
    title1_h2:
      'The digits on your ticket must match in the correct order to win.',
    content1: 'Here’s an example lottery draw, with two tickets, A and B.',
    conditionA:
      'Ticket A: The first 3 digits and the last 2 digits match, but the 4th digit is wrong, so this ticket only wins a "Match first 3" prize.',
    conditionB:
      'Ticket B: Even though the last 5 digits match, the first digit is wrong, so this ticket didn’t win.',
    conditionC:
      'Prize brackets don’t ‘stack’: if you match the first 3 digits in order, you’ll only win prizes from the ‘Match 3’ bracket, and not from ‘Match 1’ and ‘Match 2’.',
    LotteryRules: 'Lottery rules',
    title2_h2: 'Winning time: 10:00 and 22:00 (UTC+8) every day',
    content2:
      'The winning number is the last 6 digits of the hash value of the last lottery purchase order transaction in the current round. If the hash value of the transaction contains less than 6 digits, the second last lottery order transaction hash value will be taken. 6 digits, and so on.',
    prizeFund: 'Prize funding and distribution',
    title3_h2: 'Source of prizes:',
    source1:
      '1. The project will initially inject 100,000 MEB from the DAO Ecological Fund at a time.',
    source2:
      '2. All MEB paid by ticket buyers for the round of lottery tickets will be injected into the prize pool.',
    source3:
      '3. After each round, if no one wins a prize in one of the prize groups, the prize of that group will be rolled over to the total prize pool of the next round.',
    PrizeDis: 'Prize distribution',
    MatchFirst: 'Match first {number}',
    DaoFund: 'DAO Ecological Fund'
  },
  // 我的
  my: {
    checkBtn: 'Check Now',
    noPrize: 'No prizes to collect...\nGood luck next time',
    Round: 'Round',
    date: 'Date',
    quantity: 'Quantity',
    results: 'Results',
    details: 'Details'
  },
  // 彈窗
  pop: {
    round: 'Round',
    your: 'your lottery ticket',
    totalTicket: 'Total lottery',
    whyNot: "why i didn't win?",
    hitTicket: 'Winning lottery ticket',
    hit: 'Hit the top {number}',
    Buy: 'Buy',
    Tickets: 'Tickets',
    Enable: 'Enable',
    BuyInstantly: 'Buy Instantly',
    needPay: 'Need pay',
    Balance: 'Balance',
    View: 'View',
    Edit: 'Edit',
    Numbers: 'Numbers',
    space: '&nbsp;',
    buyTips:
      '"Buy Instantly" chooses random numbers, with no duplicates among your tickets.',
    randomlySelected: 'Randomly Selected',
    buyNow: 'Buy Now',
    back: 'Go Back',
    ok: 'OK',
    reward: 'Collect bonuses',
    yourRewadr: 'you win',
    receive: 'Receive',
    detailNotice:
      'The lottery number is the highest value of the transaction hash value of the' +
      'last lottery purchase order in the current round.' +
      'The last 6 digits, if the transaction contains less than 6 digits, take the reciprocal' +
      'The last 6 digits of the hash of the second purchase order, and so on.',
    detailTitle:
      'The hash value of the last lottery purchase order transaction in this round is:'
  }
}
export const ZH = {
  main: {
    hour: '小時',
    min: '分鐘',
    rewardNotice1: '距開獎還有',
    rewardNotice2: '',
    BuyTickets: '購買彩票',
    DrawTime: '開獎時間',
    haveTickets:
      '本回合您有<span style="font-weight: Bold;">{number}</span>張彩票',
    viewTickets: '查看彩票',
    winPrize: '當前可贏取獎金',
    history: '歷程記錄',
    gameIntroduction: '玩法介紹',
    my: '我的',
    comingSoon: '即將銷售'
  },
  history: {
    Round: '回合',
    Drawn: '已開獎',
    Latest: '最新',
    WinningNumber: '中獎號碼',
    Prizepot: '獎金池',
    totalPlayer: '此回合的玩家總數:',
    rules: '以相同的順序命中彩票以贏取獎勵份額。',
    MatchFirst: '命中前<span style="font-weight: Bold;"> {number} </span>位',
    DaoFund: 'DAO生態基金',
    checkResult: '查詢開獎詳情'
  },
  // 玩法介紹
  rules: {
    WinningCriteria: '中獎條件',
    title1_h2: '彩票上的數字必須以正確的順序命中才能中獎',
    content1: '這是一個開獎範例，有A和B兩張彩券。',
    conditionA:
      '彩票A：前3位和後2位命中，但第4位錯誤，所以此彩票僅贏得了”命中前3位”獎。',
    conditionB:
      '彩票B：儘管命中最後5位數字，但第一位數字是錯誤的，因此這張彩票並未中獎。',
    conditionC:
      '獎金組不可”疊加”:如果您按順序命中前3位數字，則您將只能贏得”命中3位”組中的獎金，而不會贏得”命中1位”和“命中2位”組中的獎金。',
    LotteryRules: '開獎規則',
    title2_h2: '中獎時間:每天10:00和22:00(UTC+8)開獎',
    content2:
      '開獎號碼取當前回合最後一筆購買彩票訂單交易哈希值的後6位數字，如該筆交易哈希值包含的數字不足6位則取倒數第二二筆購買彩票訂單交易哈希值的後6位數字，以此類推。',
    prizeFund: '獎金來源及分配',
    title3_h2: '獎金來源:',
    source1: '1、項目初始會從DAO生態基金一 次性注入100,000 MEB。',
    source2: '2、購票者購買該回合彩票所支付的MEB將全部注入到獎池。',
    source3:
      '3、在每一回合之後，如果其中一個獎金組中無人中獎，則該組獎金將滾存到下一回合總獎池中。',
    PrizeDis: '獎金分配',
    MatchFirst: '命中前{number}位',
    DaoFund: 'DAO生態基金'
  },
  // 我的
  my: {
    checkBtn: '查看中獎情況',
    noPrize: '沒有可收集的獎品…\n祝您下次好運',
    Round: '回合',
    date: '日期',
    quantity: '數量',
    results: '結果',
    details: '詳情'
  },
  // 彈窗
  pop: {
    round: '回合',
    your: '您的彩票',
    totalTicket: '彩票總數:',
    whyNot: '為什麼我沒中獎?',
    hitTicket: '中獎彩票:',
    hit: '命中前{number}位',
    Buy: 'B購買',
    Tickets: '彩票',
    Enable: '启用',
    BuyInstantly: '立即購買',
    needPay: '需要支付',
    Balance: '剩',
    View: '查看',
    Edit: '編輯',
    Numbers: '號碼',
    ok: '好的',
    space: '',
    buyTips: '“立即購買”會選擇隨機號碼，且您的彩票中無重複號碼。',
    randomlySelected: '隨機選取',
    buyNow: '立即購買',
    back: '返回',
    reward: '收集獎金',
    yourReward: '您中獎',
    receive: '領取',
    detailNotice:
      '開獎號碼取當前輪次最後購買彩票訂單交易哈希值的最後6位數宇，如該筆交易包含的數字不足6位則取倒數第二筆購買訂單的哈希值後6位數字，以此類推。',
    detailTitle: '本回合最後一筆購買彩票訂單交易的哈希值為：'
  }
}
