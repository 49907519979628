import i18n from '../main.js'

export default {
  SET_CHAIN (state, chain) {
    if (!chain) {
      localStorage.removeItem('chain')
    } else {
      localStorage.setItem('chain', chain)
    }
    state.chain = chain
  },
  SET_TOKEN (state, authorized) {
    if (!authorized) {
      localStorage.removeItem('authorized')
    } else {
      localStorage.setItem('authorized', authorized)
    }
    state.authorized = authorized
  },
  SET_USERINFO (state, userInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    state.userInfo = userInfo
  },
  SET_WEBVIEW_URL (state, webviewUrl) {
    state.webviewUrl = webviewUrl
  },
  SET_SCENE (state, scene) {
    state.scene = scene
  },
  SET_MB_LANG (state, mbLang) {
    localStorage.setItem('mbLang', mbLang)
    // console.log(i18n.t('home.title'))
    if (i18n) {
      i18n.locale = mbLang // i18n语言切换
    }
    state.mbLang = mbLang
  },
  SET_TAB_BAR_LIST (state, tabBarList) {
    const list = i18n.t('tabBar.list')
    localStorage.setItem('tabBarList', list)
    state.tabBarList = list
  },
  SET_MY_ACCOUNT (state, myAcount) {
    if (!myAcount) {
      localStorage.removeItem('mbMyAcount')
    } else {
      localStorage.setItem('mbMyAcount', myAcount)
    }
    state.myAcount = myAcount
  },
  SET_LOADING (state, isLoading) {
    state.isLoading = isLoading
  },
  SET_LOADING_ICON_TYPE (state, loadingIconType) {
    state.loadingIconType = loadingIconType
  },
  SET_NEED_CREATE_ACCOUNT (state, needCreateAccount) {
    state.needCreateAccount = needCreateAccount
  }
}
