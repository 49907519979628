<template>
  <vanSticky>
    <div class="header-warpper">
      <img class="logo-img" src="@/assets/logo.png" @click="handleLogo" />
      <div class="logo-language" @click="changeLang">
        {{ mbLang === 'ZH' ? 'English' : '繁體中文' }}
      </div>
      <div class="tab-list">
        <van-tabs
          v-model="currentPath"
          background=""
          color="#25E2B1"
          title-style="vant-tab"
          title-active-color="#25E2B1"
          title-inactive-color="#11152D"
          @click="handleClick"
          @change="onChange"
        >
          <van-tab
            v-for="(item, index) in list"
            :name="item.url"
            :key="index"
            :title="item.text"
          >
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <LpOotion
      v-if="lpShow"
      position="top"
      @closeOption="closeOption"
      @miniListClick="miniListClick"
    />
  </vanSticky>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LpOotion from './LpOption.vue'
export default {
  components: { LpOotion },
  data () {
    return {
      currentPath: '',
      pathList: ['/', '/notice', '/gameGuild', '/staking', '/twins'],
      lpShow: false
    }
  },
  computed: {
    ...mapState(['mbLang']),
    list () {
      return this.$t('tabBar.list')
    },
    miniList () {
      return this.$t('tabBar.miniList')
    }
  },
  methods: {
    ...mapActions(['changeMbLang']),
    handleLogo () {
      window.open('https://meblox.io/')
    },
    handleClick (url) {
      if (url === 'comingSoon') {
        return this.$toast(this.$t('common.ComingSoon'))
      }
      if (url === '/community') {
        return this.$toast(this.$t('common.updating'))
      }
      if (url === '/ecology') {
        return window.open('https://eggdao.io/')
      }
      this.$gbUtils.handleToPage(url)
      // if (url === '/staking') this.lpShow = !this.lpShow
      // else {
      //   this.lpShow = false
      //   this.$gbUtils.handleToPage(url)
      // }
    },
    onChange (url) {
      // this.$gbUtils.handleToPage(url)
    },
    getPath () {
      console.log(this.$route)
      if (this.pathList.indexOf(this.$route.path) !== -1) {
        this.currentPath = this.$route.path
      }
    },
    changeLang () {
      const lang = this.mbLang === 'ZH' ? 'EN' : 'ZH'
      this.changeMbLang(lang)
    },
    miniListClick (item, index) {
      this.lpShow = false
      if (index === 0 || index === 3) {
        window.location = item.url
        return
      }
      this.$gbUtils.handleToPage(item.url)
      // document.title = item.text
    },
    // 弹窗显隐
    closeOption () {
      this.lpShow = !this.lpShow
    }
  },
  watch: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.header-warpper {
  width: 100%;
  height: 60px;
  min-width: 500px;
  max-width: 1200px;
  position: relative;
  z-index: 9999;
  margin: auto;
  z-index: 1;
  // background: black;
  .logo-img {
    height: 40px;
    width: auto;
    position: absolute;
    top: 10px;
    left: 25px;
    cursor: pointer;
  }
  .logo-language {
    position: absolute;
    top: 15px;
    right: 25px;
    cursor: pointer;
  }
  .tab-list {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 532px;
    height: 60px;
    margin: auto;
    color: #fff;
    .van-tabs {
      height: 60px;
      /deep/ .van-tabs__wrap {
        height: 100%;
      }
      /deep/ .van-tab {
        font-size: 16px !important;
        font-weight: bold;
      }
    }
  }
}
.tab-list {
  position: relative;
  .lp {
    position: absolute;
    z-index: 9999;
    top: 60px;
    margin-left: 55%;
    width: 75px;
    font-size: 12px;
    color: #a2a2a2;
    background-color: #ffffff;
    text-align: center;
    height: 160px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    z-index: 1;
    cursor: pointer;
    div {
      height: 40px;
      line-height: 38px;
      border-top: 1px solid #f0f0f0;
    }
  }
  .triangle-img {
    width: auto;
    height: 8px;
    margin-left: 8px;
  }
}
</style>
