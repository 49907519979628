import contractsInfo from '../utils/contractsInfo1'
export default {
  server: 'https://web-dapp-test.bljcoco.com/api', // 测试服
  imageUrl: '', // OSS地址，暂时不启用
  provider: 'https://mainnet.infura.io/v3/79672fa72d88459e9f530742628392aa', // 公链地址
  contractsInfo: contractsInfo, // pancake router && token合约地址
  factoryAddress: '0xB7926C0430Afb07AA7DEfDE6DA862aE0Bde767bc', // pancake factory
  lockAddress: '0xe1574B31e6701388083d07bF221afd1bF533B3Ff', // 测试锁仓合约
  singleStakeAddress: '0xa4E4355144589d4Bca9Ad78b7Bf0a9F8Ce989E52', // 单币质押合约
  rewardAddress: '0x496F512d855C3687A9F55de20CD636c50725CE35', // LP生态奖励质押合约
  mebMusdAddress: '', // LP生态奖励质押合约
  timeOption: [{
    name: 360,
    percent: 10,
    lockSecond: 360
  },
  {
    name: 180,
    percent: 4,
    lockSecond: 180
  },
  {
    name: 60,
    percent: 1,
    lockSecond: 60
  }],
  pancakeUrl: 'https://pancake.kiemtienonline360.com/#/',
  pancakeAdd: `add/${contractsInfo.tokenUSDTContract.address}/${contractsInfo.tokenGEPContract.address}`,
  pancakeAddMebMusd: `add/${contractsInfo.tokenGEPContract.address}/${contractsInfo.tokenMUSDContract.address}`,
  pancakePool: 'pool',
  bscBrowser: 'https://testnet.bscscan.com',
  // 币安测试链ID
  ethChainNumberId: 97,
  // 币安链配置
  chainConfig: {
    chainId: '0x61',
    chainName: 'Smart Chain - Testnet',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18
    },
    blockExplorerUrls: ['https://testnet.bscscan.com']
  }
}
