export const EN = {
  wareHouseInfo: {
    balance: 'balance',
    withdrawal: 'Withdrawal',
    withdarwalNotice:
      '{extractionCost}MEB will be charged as a handling fee each time. As the price of MEB changes, the amount of handling fee will be adjusted at any time, and the fee will only be used in exchange for BNB as a coin GAS fee.',
    notice: 'Auto reprint after manual approval',
    records: 'Recent Records',
    number: 'Number',
    date: 'Date',
    operation: 'Operation',
    success: 'Success',
    status1: 'Airdrop',
    status2: 'Node airdrop',
    status3: 'Node Reward',
    status4: 'Progress',
    status5: 'Success',
    status6: 'Failed',
    status7: 'Gas',
    status8: 'Gas-return',
    status9: 'Return',
    status10: 'Progress',
    status11: 'NFT Rewards',
    status12: 'Referral rewards',
    status13: 'Referral rewards',
    status14: 'Referral rewards',
    status15: 'Subsidies',
    allLoad: 'All loaded',
    takeOut: 'Withdraw',
    takeOutNotice:
      'Each withdrawal is up to {maxWithdrawAmountEach} MEB, and the number of withdrawals is unlimited.',
    confirm: 'confirm',
    cancel: 'cancel',
    point: 'Cumulative Node Reward',
    detail: 'Detail',
    nodeDetail: 'Node Reward Detail'
  }
}
export const ZH = {
  wareHouseInfo: {
    balance: '剩',
    withdrawal: '提取',
    withdarwalNotice:
      '*每次提取會燃燒{extractionCost}MEB。燃燒的MEB數量隨市場波動，是用作換取BNB的提取GAS。',
    notice: '人工審核完成後自動轉載',
    records: '近期記錄',
    number: '數量',
    date: '時間',
    operation: '操作',
    success: '提取成功',
    status1: '空投',
    status2: '節點空投',
    status3: '工會分紅',
    status4: '提取-進行中',
    status5: '提取-成功',
    status6: '提取-失敗',
    status7: '手續費',
    status8: '手續費-退回',
    status9: '提取-退回',
    status10: '進行中',
    status11: '騎士卡獎勵',
    status12: '推薦社區獎勵',
    status13: '社區獎勵',
    status14: '社區獎勵',
    status15: '社區補貼',
    allLoad: '已經全部加載完畢',
    takeOut: '提取',
    takeOutNotice: '每次提取最多{maxWithdrawAmountEach}MEB，提取次數無限制。',
    confirm: '確認',
    cancel: '取消',
    point: '累計節點獎勵',
    detail: '詳情',
    nodeDetail: '節點獎勵明細'
  }
}
